import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { SingleContext } from "../hooks/operatingContext";
import { SingleAgencyResponse } from "../hooks/operatingAgency";

type OperationIdsState = Record<string, string[]>;
type SelectedAgencyState = Record<string, SingleAgencyResponse>;

/**
 * 🚨 Please, don't export these states to be write outside the Core
 * They should only be managed by the useOperatingContext and useOperationIds hooks
 * It's ok to read their values, but please don't write them outside the hooks mentioned above
 */

// This atom is used to store the global selected context from the context dropdown
// The selected context is stored by the app name, so each app can have its own selected context
export const contextSelectedState = atomWithStorage<
  Record<string, SingleContext | undefined>
>("contextSelected", {}, undefined, { getOnInit: true });
contextSelectedState.debugLabel = "Context";

// This atom is used to store the selected business context from the agency dropdown
// The selected business context is stored by the app name and selected Context, so each app and context can have its own selected business context
export const selectedAgencyState = atom<
  Record<string, SelectedAgencyState | undefined>
>({});
selectedAgencyState.debugLabel = "Agency";

// This atom is used to store the global selected operation ids from the Operation dropdown
// The selected operations are stored by app name and business contextId, so each app and business context can have its own selected operations
export const operationIdsSelectedState = atom<
  Record<string, OperationIdsState | undefined>
>({});
operationIdsSelectedState.debugLabel = "Operation Ids by Business Context";
