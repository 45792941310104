import * as React from "react";
import { useLocation } from "react-router-dom";

const NotFound: React.FC = () => {
  const location = useLocation();

  return (
    <div className="d-flex align-items-center justify-content-center p-5 h-100 bg-danger text-light">
      <div>
        <h1 className="display-1">{location.pathname}</h1>
        <p className="d-block">
          I&#8217;m really sorry but I could not find anything at that route{" "}
          <span role="img" aria-label="sorry face">
            😕
          </span>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
