/**
 * Use product and design-supplied guidelines to determine the user's locale.
 * There are three sources:
 *
 * 1. The user's own preference, which is set in the User Service (via the
 *    Settings app)
 * 2. The browser's locale setting (which of course we cannot control)
 * 3. The URI: `us.app.granular.ag` and `br.app.granular.ag`
 *
 * As you can tell, this is a bit complicated than just using the `useUser`
 * hook and just getting their preferred locale! This is why we 'slice' the
 * locale away from the user hook and make it it's own thing.
 *
 * NOTE: This entire module is based on _old_ Fabric product and design
 * decisions. We may not need this later.
 */

import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
  SupportedCountry,
  TIME_IN_MS,
  type SupportedLocale,
} from "@granular/fabric3-definitions";
import getUserLocale from "./userLocale";
import { coreQueryClient } from "../../services/trpc";

/**
 * Takes a given locale and tries to return a standard locale string. This is
 * mostly used to turn lowercased locale strings ("en-us") to their standard
 * forms ("en-US").
 *
 * See `@granular/fabric3-definitions:constants` for additional context. If you
 * modify this implementation, you will have to modify that module as well. If
 * you forget, it's OK: the typechecker will yell at you 😄
 */
const standardizeLocale = (locale: string): string => {
  const [language, territory] = locale.split("-");

  return territory
    ? `${language?.toLowerCase()}-${territory.toUpperCase()}`
    : language?.toLowerCase() ?? "en";
};

/**
 * Aliasing since I think it's
 *
 * (a) A better name to avoid confusion, and
 *
 * (b) Might allow us to do funky things with its return values later
 *     (if at all). Run it through the refinement function defined in
 *     this module defensively in case people (QA especially) get
 *     clever... 😏
 */
const getBrowserLocale = (): string => standardizeLocale(getUserLocale());

/**
 * Determines the locale for the user _taking into account their individual
 * locale preference_. You'd think that this would be as easy as them picking
 * their locale on their user settings page. Oh child, you'd be wrong.
 *
 * Why can this get complicated? Because the logic explained here, in code,
 * captures product requirements. Example: "What language should the user see
 * when they've loaded an app, set their language prefs to Canadian English,
 * set their browsers to Spanish, and the User Service fails?"
 *
 * That extreme case is what this function deals with.
 *
 * Thankfully for you, this is the ONLY place (in the frontend at least) where
 * you'd have to modify any updated logic about user locale preference. People
 * and languages are complex, so I'm going to try to make this as readable as
 * possible. You need to check with your PM to update any of the logic that
 * follows 🌸
 */
const computeLocaleWith = (
  userPreferredLocale:
    | SupportedLocale
    | Lowercase<SupportedLocale>
    | SupportedCountry,
): SupportedLocale => {
  const browserLocale = getBrowserLocale();

  /**
   * Yes, this can be set to `DEFAULT_LOCALE` and we can eliminate a few
   * conditionals below. But this is simpler and more *readable*. Keep
   * things that way 💫
   */
  let computedLocale: SupportedLocale;

  /**
   * If the User's preferred locale is found, it most likely means that
   * the User Service call went through. So do two things:
   *
   * (1) Refine the User Service-supplied locale to a standard locale
   *     E.g. "pt-br" to "pt-BR"
   *
   * (2) Use this refined locale for a defensive check in case the User
   *     Service provides a locale we don't support. Note that this is a
   *     *very* low-likelihood event. If the locale is not supported,
   *     just use the default locale.
   */
  if (userPreferredLocale) {
    const standardizedLocale = standardizeLocale(userPreferredLocale);

    computedLocale = (SUPPORTED_LOCALES as readonly string[]).includes(
      standardizedLocale,
    )
      ? (standardizedLocale as SupportedLocale)
      : DEFAULT_LOCALE;
  } else if (
    browserLocale &&
    (SUPPORTED_LOCALES as readonly string[]).includes(browserLocale)
  ) {
    /**
     * If the user's locale is not found (or is unsupported), turn to the
     * browser's locale. If the browser is set to something we support,
     * use it.
     */
    computedLocale = browserLocale as SupportedLocale;
  } else {
    /**
     * All else failed. Just use the default locale so the user sees
     * something lol.
     */
    computedLocale = DEFAULT_LOCALE;
  }

  return computedLocale;
};

/**
 * Provide an answer to "Which locale should I use to internationalize the
 * user's experience?" You may naïvely think that this is as simple as reading
 * the `User` object from the User Service and just using the `defaultLanguage`
 * property.
 *
 * But it's more complicated than that (of course). Read the
 * `computeLocaleWith` function in the `useUserLocale` module in the Fabric3
 * Core for more information.
 *
 * NOTE: Locales from the User Service are always stored in lowercase (`en-us`,
 * `pt-br`) whereas they're returned like this: `en-US`, `pt-BR`. This is how
 * we recommend you name your language translation files as well.
 *
 */
export const useUserLocale = (): SupportedLocale => {
  const [data] = coreQueryClient.user.profile.useSuspenseQuery(undefined, {
    staleTime: TIME_IN_MS.DAY,
  });

  /**
   * NOTE: This is a historical mistake. The key we are looking for in the
   * `User` object really should be 'locale' instead of 'language' but here we
   * are 🤷‍♀️
   *
   * NOTE: We _could_ shout (!) because user data is part of the bootstrap and
   * we have a very reasonable expectation that it will be available... but
   * let's not do that.
   */
  return computeLocaleWith(data?.defaultLanguage);
};
