import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

/**
 * This doesn't have to be its own module but Vite's HMR will complain and
 * clutter output. I hate this but don't want to tweak defaults. Let's leave
 * this alone.
 */

/**
 * Wrap `Conatainer` in `Shell` so that `Container` has LD capabilities
 */
import { Shell } from "@granular/fabric3-core";
import Container from "./Container";
import Logout from "./components/Logout";

const rootElement = document.querySelector("#root");
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/*"
            element={
              <Shell>
                <Container />
              </Shell>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>,
  );
}
