/**
 * Show a dot to indicate the app's feature-flag status. This component is
 * never seen by our customers/clients and is our little secret 😉
 *
 * We need to account for just three states:
 *
 * - Not Visible, Not Routable ---> Red
 * - Not Visible, Routable ---> Orange
 * - Visible, Not Routable ---> Gray
 */

import { createRef } from "react";
import { GKTooltip } from "@granular/gds";

/**
 * TODO: Speak to @robglazebrook and use GDS colors here... I could not find
 * them on the GDS website 🤷‍♂️
 */
const COLOR_MAP = {
  red: "#ff4141",
  orange: "#ff9900",
  gray: "#74787C",
};

export type DotColor = keyof typeof COLOR_MAP;

const Dot: React.FC<{
  color: DotColor;
  toolTipText?: string;
}> = ({ toolTipText, color = COLOR_MAP.red }) => {
  const ref = createRef<HTMLDivElement>();

  return (
    <>
      <div
        ref={ref}
        style={{
          backgroundColor: COLOR_MAP[color as DotColor],
          borderRadius: "50%",
          display: "inline-block",
          width: "10px",
          height: "10px",
          marginRight: "5px",
        }}
      />
      {toolTipText && (
        <GKTooltip target={ref} placement="auto">
          {toolTipText}
        </GKTooltip>
      )}
    </>
  );
};

export default Dot;
