import { useCallback, useState } from "react";

/**
 * TODO: Add ability to just read a key (i.e., not set it) and tie React state
 * to it. Work with \@timbendt and \@andrewmeyer on this.
 */
export const useLocalStorage = (
  key: string,
  initialValue: string,
  overwriteExisting = true,
): [string, (newVal: string | null) => void] => {
  const [value, setValue] = useState(
    overwriteExisting
      ? initialValue
      : localStorage.getItem(key) ?? initialValue,
  );

  const updatedSetValue = useCallback(
    (newValue: string | null) => {
      if (newValue) {
        localStorage.setItem(key, newValue);
      } else {
        localStorage.removeItem(key);
      }

      setValue(newValue ?? initialValue);
    },
    [key, initialValue],
  );

  return [value, updatedSetValue];
};
