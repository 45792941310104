/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * cycles-svc
 * OpenAPI spec version: 0.0.1.644
 */
import {
  z as zod
} from 'zod'

/**
 * Lists all cycles.
 * @summary Get Cycles
 */
export const getCyclesQueryParams = zod.object({
  "region": zod.string().nullish()
})

export const getCyclesResponse = zod.object({
  "cycles":  zod.object({
  "business_cycles": zod.array( zod.object({
  "id": zod.string().uuid(),
  "start_date": zod.date(),
  "end_date": zod.date(),
  "label": zod.string(),
  "previous": zod.string().uuid().nullish(),
  "next": zod.string().uuid().nullish()
})),
  "crop_seasons": zod.array( zod.object({
  "id": zod.string().uuid(),
  "start_date": zod.date(),
  "end_date": zod.date(),
  "crop_id": zod.string().uuid(),
  "business_cycle_id": zod.string().uuid(),
  "season_index": zod.number(),
  "previous": zod.string().uuid().nullish(),
  "next": zod.string().uuid().nullish()
}))
})
})


/**
 * Lists business cycles, optionally by start and end date.
 * @summary List Business Cycles
 */
export const getBusinessCyclesQueryParams = zod.object({
  "region": zod.string().nullish()
})

export const getBusinessCyclesResponse = zod.object({
  "business_cycles": zod.array( zod.object({
  "id": zod.string().uuid(),
  "start_date": zod.date(),
  "end_date": zod.date(),
  "label": zod.string(),
  "previous": zod.string().uuid().nullish(),
  "next": zod.string().uuid().nullish()
}))
})


/**
 * Gets crop seasons, optionally by crop id, start and end date, business cycle id, and season index.
NOTE: this search is optimized for the core Corteva 8 crops.
 * @summary List Crop Seasons
 */
export const getCropSeasonsQueryParams = zod.object({
  "region": zod.string().nullish()
})

export const getCropSeasonsResponse = zod.object({
  "crop_seasons": zod.array( zod.object({
  "id": zod.string().uuid(),
  "start_date": zod.date(),
  "end_date": zod.date(),
  "crop_id": zod.string().uuid(),
  "business_cycle_id": zod.string().uuid(),
  "season_index": zod.number(),
  "previous": zod.string().uuid().nullish(),
  "next": zod.string().uuid().nullish()
}))
})


