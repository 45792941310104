import { GKButton } from "@granular/gds";
import { TRPCClientError } from "@trpc/react-query";
import type { FallbackProps } from "react-error-boundary";

/**
 * Error fallback component to display when an error boundary is triggered
 * The error boundary is triggered when a fatal error occurs in a component that prevents us from displaying the actual content.
 * It's better to display an error state instead a blank page or empty data, which can lead the user to believe that their operation
 * is empty because it hasn't data and not because an error occurred.
 *
 * When necessary you can raise a fatal error triggering the error boundary manually using:
 * @example
 * ```typescript
 * import { useErrorBoundary } from "react-error-boundary";
 *
 * const { showBoundary } = useErrorBoundary();
 *
 * try {
 *   // your code
 * } catch (error) {
 *   showBoundary(error); // firing the error boundary manually
 * }
 * ```
 */
export const ErrorFallback: React.FC<FallbackProps> = ({
  resetErrorBoundary,
  error,
}) => {
  if (
    error instanceof TRPCClientError &&
    (error?.meta?.response as { status?: number })?.status === 403 &&
    error?.message?.toLowerCase()?.includes("super-user")
  ) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center p-5 h-100 bg-danger text-light">
        <h1
          className="text-center"
          data-qa="container-errorBoundary-unauthorized"
        >
          Super-users and super-readers are not supported.
        </h1>
        <GKButton
          size={"lg"}
          color="danger"
          style={{
            borderColor: "white",
            width: "25rem",
          }}
          className="mt-3"
          data-qa="container-errorBoundary-logout-btn"
          onClick={() => window.location.assign("/logout")}
        >
          Log out
        </GKButton>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-5 h-100 bg-danger text-light">
      <h1
        className="text-center"
        data-qa="container-errorBoundary-unableToLoadPage"
      >
        {/* TODO: Change this message with something aligned with the design team */}
        An error ocurred while loading this page{" "}
        <span aria-label="Sad face">😔</span>
      </h1>
      <GKButton
        size={"lg"}
        color="danger"
        style={{
          borderColor: "white",
          width: "25rem",
        }}
        className="mt-3"
        data-qa="container-errorBoundary-reset-btn"
        onClick={() => resetErrorBoundary()}
      >
        Refresh
      </GKButton>
    </div>
  );
};
