import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/ErrorFallback";

/**
 * A wrapper for the ErrorBoundary component used to catch errors in the Container level
 * @param children - The children to render
 */
const ContainerErrorBoundary: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
        {children}
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

export default ContainerErrorBoundary;
