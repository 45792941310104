/**
 * This being a global "loading" indicator. It spans the whole width and height
 * of the app. Used by the `Login` component, for example.
 *
 * Nothing complicated at all. Please keep it this stupid.
 *
 * NOTE: 👉 DO NOT add a dependency on GDS!
 *       👉 Other than this component, the Core should be headless!
 */

const Component: React.FC = () => (
  <>
    {/*
      Yeah this is a bit kludgy but it prevents:

      a) A CSS artifact from beign emitted by the build process, or
      b) An additional Vite plugin that injects CSS into JS
    */}
    <style type="text/css">
      {`
        @keyframes loading-fade-in {
          0% {
            opacity: 0.25;
          }
          100% {
            opacity: 1;
          }
        }
      `}
    </style>

    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/*
      TODO: Add contact info for when this will inevitably hang. Add a phone
      number, add an email address.
    */}
      <svg
        height="144"
        width="144"
        xmlns="http://www.w3.org/2000/svg"
        fill="#C03031"
        viewBox="0 0 28 28"
        style={{
          animation: "loading-fade-in infinite alternate ease-in-out 0.5s",
        }}
      >
        <path d="M0 13V0h28v13H0zm0 15V15h13v13H0zm22-7v-6h6v6h-6zm-7 7v-5h13v5H15z" />
      </svg>
    </div>
  </>
);

export default Component;
