/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * crops-svc-lambda
 * OpenAPI spec version: 0.0.2
 */
import {
  z as zod
} from 'zod'

/**
 * get commodities
 * @summary list commodities
 */
export const getV4CommoditiesQueryParams = zod.object({
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "include_unmatched": zod.boolean().optional()
})

export const getV4CommoditiesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CommoditiesResponse = zod.object({
  "commodities": zod.array( zod.object({
  "base_unit": zod.string(),
  "commodity": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "settings":  zod.object({
  "base_unit": zod.enum(['kg', 'seeds']),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "planting_dates": zod.array(zod.string()).optional(),
  "display_unit_metric":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "display_unit_imperial":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "metrics": zod.array(zod.enum(['dry_mass', 'turnout', 'silage_yield', 'total_lint', 'dry_matter'])).optional(),
  "metrics_constants":  zod.object({
  "dry_mass":  zod.object({
  "target_moisture": zod.number().optional()
}).optional(),
  "turnout":  zod.object({
  "default_turnout_percentage": zod.number().optional()
}).optional(),
  "silage_yield":  zod.object({
  "standardized_dry_matter_percentage": zod.number().optional()
}).optional()
}).optional(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid()
}).optional(),
  "active": zod.boolean().optional()
}))
})


/**
 * get commodity by id
 * @summary get commodity by id
 */
export const getV4CommoditiesCommodityIdParams = zod.object({
  "commodity_id": zod.string()
})

export const getV4CommoditiesCommodityIdQueryParams = zod.object({
  "include_settings": zod.boolean().optional()
})

export const getV4CommoditiesCommodityIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CommoditiesCommodityIdResponse = zod.object({
  "base_unit": zod.string(),
  "commodity": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "settings":  zod.object({
  "base_unit": zod.enum(['kg', 'seeds']),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "planting_dates": zod.array(zod.string()).optional(),
  "display_unit_metric":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "display_unit_imperial":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "metrics": zod.array(zod.enum(['dry_mass', 'turnout', 'silage_yield', 'total_lint', 'dry_matter'])).optional(),
  "metrics_constants":  zod.object({
  "dry_mass":  zod.object({
  "target_moisture": zod.number().optional()
}).optional(),
  "turnout":  zod.object({
  "default_turnout_percentage": zod.number().optional()
}).optional(),
  "silage_yield":  zod.object({
  "standardized_dry_matter_percentage": zod.number().optional()
}).optional()
}).optional(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid()
}).optional(),
  "active": zod.boolean().optional()
})


/**
 * trigram matching on crop commodities
 * @summary get commodities matches
 */
export const getV4CommoditiesMatchesQueryParams = zod.object({
  "term": zod.string().optional(),
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "minimum_confidence": zod.number().optional(),
  "sort_by": zod.string().optional()
})

export const getV4CommoditiesMatchesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CommoditiesMatchesResponse = zod.object({
  "commodities": zod.array( zod.object({
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "crop": zod.string(),
  "commodity": zod.string(),
  "trigram_score": zod.number(),
  "es_score": zod.number(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional()
})).optional()
})


/**
 * Get commodity settings by commodity id
 * @summary get commodity settings by commodity id
 */
export const getV4CommoditiesSettingsCommodityIdParams = zod.object({
  "commodity_id": zod.string()
})

export const getV4CommoditiesSettingsCommodityIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CommoditiesSettingsCommodityIdResponse = zod.object({
  "base_unit": zod.enum(['kg', 'seeds']),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "planting_dates": zod.array(zod.string()).optional(),
  "display_unit_metric":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "display_unit_imperial":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "metrics": zod.array(zod.enum(['dry_mass', 'turnout', 'silage_yield', 'total_lint', 'dry_matter'])).optional(),
  "metrics_constants":  zod.object({
  "dry_mass":  zod.object({
  "target_moisture": zod.number().optional()
}).optional(),
  "turnout":  zod.object({
  "default_turnout_percentage": zod.number().optional()
}).optional(),
  "silage_yield":  zod.object({
  "standardized_dry_matter_percentage": zod.number().optional()
}).optional()
}).optional(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid()
})


/**
 * Get list of crop types
 * @summary list crop types
 */
export const getV4CropTypesQueryParams = zod.object({
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "include_unmatched": zod.boolean().optional()
})

export const getV4CropTypesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CropTypesResponse = zod.object({
  "crop_types": zod.array( zod.object({
  "base_unit": zod.string().optional(),
  "crop_type": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "active": zod.boolean().optional()
})).optional()
})


/**
 * get crop type by id
 * @summary get crop by id
 */
export const getV4CropTypesCropTypeIdParams = zod.object({
  "crop_type_id": zod.string()
})

export const getV4CropTypesCropTypeIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CropTypesCropTypeIdResponse = zod.object({
  "base_unit": zod.string().optional(),
  "crop_type": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "active": zod.boolean().optional()
})


/**
 * trigram matching on crop crop_type
 * @summary get crop type matches
 */
export const getV4CropTypesMatchesQueryParams = zod.object({
  "term": zod.string().optional(),
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "minimum_confidence": zod.number().optional(),
  "sort_by": zod.string().optional()
})

export const getV4CropTypesMatchesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CropTypesMatchesResponse = zod.object({
  "crop_types": zod.array( zod.object({
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "crop": zod.string(),
  "crop_type": zod.string(),
  "trigram_score": zod.number(),
  "es_score": zod.number(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional()
})).optional()
})


/**
 * Get crop list
 * @summary list crops
 */
export const getV4CropsQueryParams = zod.object({
  "include_unmatched": zod.boolean().optional()
})

export const getV4CropsHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CropsResponse = zod.object({
  "crops": zod.array( zod.object({
  "base_unit": zod.string().optional(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "settings":  zod.object({
  "base_unit": zod.enum(['kg', 'seeds']),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "planting_dates": zod.array(zod.string()).optional(),
  "display_unit_metric":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "display_unit_imperial":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "metrics": zod.array(zod.enum(['dry_mass', 'turnout', 'silage_yield', 'total_lint', 'dry_matter'])).optional(),
  "metrics_constants":  zod.object({
  "dry_mass":  zod.object({
  "target_moisture": zod.number().optional()
}).optional(),
  "turnout":  zod.object({
  "default_turnout_percentage": zod.number().optional()
}).optional(),
  "silage_yield":  zod.object({
  "standardized_dry_matter_percentage": zod.number().optional()
}).optional()
}).optional()
}).optional(),
  "active": zod.boolean().optional()
})).optional()
})


/**
 * Get crop by id
 * @summary get crop by id
 */
export const getV4CropsCropIdParams = zod.object({
  "crop_id": zod.string()
})

export const getV4CropsCropIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CropsCropIdResponse = zod.object({
  "base_unit": zod.string().optional(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "settings":  zod.object({
  "base_unit": zod.enum(['kg', 'seeds']),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "planting_dates": zod.array(zod.string()).optional(),
  "display_unit_metric":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "display_unit_imperial":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "metrics": zod.array(zod.enum(['dry_mass', 'turnout', 'silage_yield', 'total_lint', 'dry_matter'])).optional(),
  "metrics_constants":  zod.object({
  "dry_mass":  zod.object({
  "target_moisture": zod.number().optional()
}).optional(),
  "turnout":  zod.object({
  "default_turnout_percentage": zod.number().optional()
}).optional(),
  "silage_yield":  zod.object({
  "standardized_dry_matter_percentage": zod.number().optional()
}).optional()
}).optional()
}).optional(),
  "active": zod.boolean().optional()
})


/**
 * @summary get crop by ids
 */
export const postV4CropsSyncDataHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const postV4CropsSyncDataBody = zod.object({
  "keys": zod.array(zod.string())
})

export const postV4CropsSyncDataResponse = zod.object({
  "crops": zod.array( zod.object({
  "base_unit": zod.string().optional(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "settings":  zod.object({
  "base_unit": zod.enum(['kg', 'seeds']),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "planting_dates": zod.array(zod.string()).optional(),
  "display_unit_metric":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "display_unit_imperial":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "metrics": zod.array(zod.enum(['dry_mass', 'turnout', 'silage_yield', 'total_lint', 'dry_matter'])).optional(),
  "metrics_constants":  zod.object({
  "dry_mass":  zod.object({
  "target_moisture": zod.number().optional()
}).optional(),
  "turnout":  zod.object({
  "default_turnout_percentage": zod.number().optional()
}).optional(),
  "silage_yield":  zod.object({
  "standardized_dry_matter_percentage": zod.number().optional()
}).optional()
}).optional()
}).optional(),
  "active": zod.boolean().optional()
})).optional()
})


/**
 * Trigram matching on crops
 * @summary get crop matches
 */
export const getV4CropsMatchesQueryParams = zod.object({
  "term": zod.string().optional(),
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "minimum_confidence": zod.number().optional(),
  "sort_by": zod.string().optional()
})

export const getV4CropsMatchesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CropsMatchesResponse = zod.object({
  "crops": zod.array( zod.object({
  "id": zod.string().uuid(),
  "crop": zod.string(),
  "trigram_score": zod.number(),
  "es_score": zod.number(),
  "uom":  zod.object({
  "harvest_unit": zod.string().optional(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds']))
}).optional(),
  "active": zod.boolean().optional()
})).optional()
})


/**
 * Get crop settings by crop id
 * @summary get crop settings by crop id
 */
export const getV4CropsSettingsCropIdParams = zod.object({
  "crop_id": zod.string()
})

export const getV4CropsSettingsCropIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV4CropsSettingsCropIdResponse = zod.object({
  "base_unit": zod.enum(['kg', 'seeds']),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "planting_dates": zod.array(zod.string()).optional(),
  "display_unit_metric":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "display_unit_imperial":  zod.object({
  "conversion_factor": zod.number().optional(),
  "value": zod.string(),
  "unit_i18n_key": zod.string()
}),
  "metrics": zod.array(zod.enum(['dry_mass', 'turnout', 'silage_yield', 'total_lint', 'dry_matter'])).optional(),
  "metrics_constants":  zod.object({
  "dry_mass":  zod.object({
  "target_moisture": zod.number().optional()
}).optional(),
  "turnout":  zod.object({
  "default_turnout_percentage": zod.number().optional()
}).optional(),
  "silage_yield":  zod.object({
  "standardized_dry_matter_percentage": zod.number().optional()
}).optional()
}).optional(),
  "id": zod.string().uuid(),
  "planted_units": zod.array(zod.enum(['kg', 'seeds'])).optional()
})


/**
 * Get crop by id
 * @deprecated
 * @summary get crop by id
 */
export const getV3CropsCropIdParams = zod.object({
  "crop_id": zod.string()
})

export const getV3CropsCropIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CropsCropIdResponse = zod.object({
  "base_unit": zod.string().optional(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})


/**
 * Get crop list
 * @deprecated
 * @summary list crops
 */
export const getV3CropsHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CropsResponse = zod.object({
  "crops": zod.array( zod.object({
  "base_unit": zod.string().optional(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})).optional()
})


/**
 * @deprecated
 * @summary get crops by id
 */
export const postV3CropsSyncDataHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const postV3CropsSyncDataBody = zod.object({
  "keys": zod.array(zod.string())
})

export const postV3CropsSyncDataResponse = zod.object({
  "crops": zod.array( zod.object({
  "base_unit": zod.string().optional(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})).optional()
})


/**
 * Trigram matching on crops
 * @deprecated
 * @summary get crop matches
 */
export const getV3CropsMatchesQueryParams = zod.object({
  "term": zod.string().optional(),
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "minimum_confidence": zod.number().optional(),
  "sort_by": zod.string().optional()
})

export const getV3CropsMatchesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CropsMatchesResponse = zod.object({
  "crops": zod.array( zod.object({
  "id": zod.string().uuid(),
  "crop": zod.string(),
  "trigram_score": zod.number(),
  "es_score": zod.number(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})).optional()
})


/**
 * Get crop settings by id
 * @deprecated
 * @summary get crop settings by id
 */
export const getV3CommoditiesCommodityIdParams = zod.object({
  "commodity_id": zod.string()
})

export const getV3CommoditiesCommodityIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CommoditiesCommodityIdResponse = zod.object({
  "base_unit": zod.string(),
  "commodity": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})


/**
 * returns the list of commodities
 * @deprecated
 * @summary list commodities
 */
export const getV3CommoditiesQueryParams = zod.object({
  "crop_id": zod.array(zod.string().uuid()).optional()
})

export const getV3CommoditiesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CommoditiesResponse = zod.object({
  "commodities": zod.array( zod.object({
  "base_unit": zod.string(),
  "commodity": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
}))
})


/**
 * trigram matching on crop commodities
 * @deprecated
 * @summary get commodity matches
 */
export const getV3CommoditiesMatchesQueryParams = zod.object({
  "term": zod.string().optional(),
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "minimum_confidence": zod.number().optional(),
  "sort_by": zod.string().optional()
})

export const getV3CommoditiesMatchesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CommoditiesMatchesResponse = zod.object({
  "commodities": zod.array( zod.object({
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "crop": zod.string(),
  "commodity": zod.string(),
  "trigram_score": zod.number(),
  "es_score": zod.number(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})).optional()
})


/**
 * get commodity by id
 * @deprecated
 * @summary get crop by id
 */
export const getV3CropsettingsCommodityIdParams = zod.object({
  "commodity_id": zod.string()
})

export const getV3CropsettingsCommodityIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional()
})

export const getV3CropsettingsCommodityIdResponse = zod.object({
  "market_unit": zod.string().optional(),
  "target_moisture": zod.number().optional(),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "transfer_unit": zod.string(),
  "planting_dates": zod.array(zod.string()).optional(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid().optional()
})


/**
 * Get crop type by id
 * @deprecated
 * @summary get crop by id
 */
export const getV3CropTypesCropTypeIdParams = zod.object({
  "crop_type_id": zod.string()
})

export const getV3CropTypesCropTypeIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CropTypesCropTypeIdResponse = zod.object({
  "base_unit": zod.string().optional(),
  "crop_type": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})


/**
 * Get list of crop types
 * @deprecated
 * @summary list crop types
 */
export const getV3CropTypesQueryParams = zod.object({
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "include_unmatched": zod.boolean().optional()
})

export const getV3CropTypesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CropTypesResponse = zod.object({
  "crop_types": zod.array( zod.object({
  "base_unit": zod.string().optional(),
  "crop_type": zod.string(),
  "crop": zod.string(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})).optional()
})


/**
 * Get crop settings by crop id
 * @deprecated
 * @summary get crop settings by crop id
 */
export const getV3CropsSettingsCropIdParams = zod.object({
  "crop_id": zod.string()
})

export const getV3CropsSettingsCropIdResponse = zod.object({
  "market_unit": zod.string().optional(),
  "target_moisture": zod.number().optional(),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "transfer_unit": zod.string(),
  "planting_dates": zod.array(zod.string()).optional(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid().optional()
})


/**
 * Returns commodity settings by provided id
 * @deprecated
 * @summary get commodity settings by commodity id
 */
export const getV3CommoditiesSettingsCommodityIdParams = zod.object({
  "commodity_id": zod.string()
})

export const getV3CommoditiesSettingsCommodityIdHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional()
})

export const getV3CommoditiesSettingsCommodityIdResponse = zod.object({
  "market_unit": zod.string().optional(),
  "target_moisture": zod.number().optional(),
  "density": zod.number().optional(),
  "harvest_dates": zod.array(zod.string()).optional(),
  "transfer_unit": zod.string(),
  "planting_dates": zod.array(zod.string()).optional(),
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid().optional()
})


/**
 * Returns crop type matches
 * @deprecated
 * @summary get crop type matches
 */
export const getV3CropTypesMatchesQueryParams = zod.object({
  "term": zod.string().optional(),
  "crop_id": zod.array(zod.string().uuid()).optional(),
  "minimum_confidence": zod.number().optional(),
  "sort_by": zod.string().optional()
})

export const getV3CropTypesMatchesHeader = zod.object({
  "X-Granular-Locale": zod.enum(['en-US', 'en-AU', 'fr-CA', 'pt-BR', 'en-CA']).optional(),
  "admin-region": zod.enum(['US', 'CA', 'AU', 'BR']).optional()
})

export const getV3CropTypesMatchesResponse = zod.object({
  "crop_types": zod.array( zod.object({
  "id": zod.string().uuid(),
  "parent_id": zod.string().uuid(),
  "crop": zod.string(),
  "crop_type": zod.string(),
  "trigram_score": zod.number(),
  "es_score": zod.number(),
  "uom":  zod.object({
  "standard_unit": zod.string().optional(),
  "planted_unit": zod.string().optional(),
  "harvest_unit": zod.string().optional()
}).optional()
})).optional()
})


