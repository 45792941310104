import { coreQueryClient } from "../services/trpc";

/**
 * Returns all the current logged user information
 */
export const useUser = () => {
  // we're using the useSuspenseQuery instead of the useQuery to ensure that user data will be loaded before rendering anything
  // with SuspenseQuery, the data will never return undefined and the fallback loading component (from Container)
  // will be shown until the data is loaded
  const [data] = coreQueryClient.user.profile.useSuspenseQuery(undefined, {
    staleTime: 60 * 1000 * 60 * 24, // 24 hours,
    useErrorBoundary: true, // throw an error boundary, we can't do nothing without the user data
  });

  return { data };
};
