import { useAlerts } from "@granular/gds";
import React from "react";
import { useLocation } from "react-router-dom";

/**
 * This component ensures that all alerts are dismissed when pathname in route changes.
 * When app A emits an alert and user switches to app B, all the current alerts of app A should be dismissed.
 * This code is a dummy component because to use alert context, we need to be inside an alert provider, and the provider
 * exits only in GKLayoutShell from Container component, so we need to put this component as a child of GKLayoutShell.
 */
export const AlertCleaner: React.FC = () => {
  const { dismissAll } = useAlerts();
  const { pathname } = useLocation();
  const [previousPathName, setPreviousPathName] =
    React.useState<string>(pathname);

  // when pathname changes, it means user navigated to a different app, so we dismiss all alerts from previous app
  if (previousPathName && previousPathName !== pathname) {
    dismissAll();
    setPreviousPathName(pathname);
  }

  // eslint-disable-next-line unicorn/no-null
  return null;
};
