import {
  GKButton,
  GKButtonGroup,
  GKCard,
  GKCheckbox,
  GKIconName,
  GKInput,
} from "@granular/gds";
import { ChatApi } from "@granular/chatbot-svc-client";
import React from "react";
import { getCurrentToken, segment } from "@granular/fabric3-core";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./Chat.module.css";

type FeedbackState = "initial" | "submitting" | "success";
type FeedbackBtnProps = {
  chatId: string;
};
export const FeedbackBtn: React.FunctionComponent<FeedbackBtnProps> = ({
  chatId,
}) => {
  const [positive, setPositive] = React.useState<boolean>();
  const [state, setState] = React.useState<FeedbackState>("initial");
  const intl = useIntl();

  const submitFeedback = (message: string, details: string) => {
    if (positive !== undefined) {
      setState("submitting");
      ChatApi.postChatFeedbackChatFeedbackPost({
        body: {
          chatId,
          details,
          message,
          positive,
        },
        authToken: `Bearer ${getCurrentToken()}`,
      })
        .then(() => {
          setState("success");
        })
        .catch(() => {
          // TODO: get error designs
          setState("success");
        });
    }
  };
  return (
    <>
      <hr />
      <div>
        {state === "initial" && (
          <GKButtonGroup>
            <GKButton
              size="sm"
              color="link"
              key={1}
              active={positive === true}
              onClick={() => setPositive(true)}
              icon={
                positive === true
                  ? GKIconName.ThumbUp
                  : GKIconName.ThumbUpOffAlt
              }
            />
            <GKButton
              size="sm"
              color="link"
              key={2}
              active={positive === false}
              onClick={() => setPositive(false)}
              icon={
                positive === false
                  ? GKIconName.ThumbDown
                  : GKIconName.ThumbDownOffAlt
              }
            />
          </GKButtonGroup>
        )}
        {state === "submitting" && (
          <div
            style={{
              display: "inline-block",
              marginLeft: "20px",
              marginRight: "20px",
            }}
            className={styles.dotElastic}
          />
        )}
        <span style={{ color: "#777777", fontSize: "12px" }}>
          {state === "initial" && (
            <FormattedMessage
              id="assistant_is_experimental"
              values={{
                termsLink: (
                  <a
                    href="https://www.corteva.com/terms-and-conditions.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage({ id: "terms_and_conditions" })}
                  </a>
                ),
              }}
            ></FormattedMessage>
          )}
          {state === "submitting" &&
            intl.formatMessage({ id: "submitting_feedback" })}
          {state === "success" &&
            intl.formatMessage({ id: "your_feedback_is_valuable" })}
        </span>
      </div>
      {positive !== undefined && state === "initial" && (
        <FeedbackCard positive={positive} submitFeedback={submitFeedback} />
      )}
    </>
  );
};

type FeedbackCardProps = {
  positive: boolean;
  submitFeedback: (message: string, details: string) => void;
};
export const FeedbackCard: React.FunctionComponent<FeedbackCardProps> = ({
  positive,
  submitFeedback,
}) => {
  const [feedbackStr, setFeedbackStr] = React.useState<string>("");
  const [btnIdx, setBtnIdx] = React.useState<number>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const options = positive
    ? [
        { key: "Correct", label: intl.formatMessage({ id: "correct" }) },
        {
          key: "Easy to understand",
          label: intl.formatMessage({ id: "easy_to_understand" }),
        },
        { key: "Complete", label: intl.formatMessage({ id: "complete" }) },
      ]
    : [
        {
          key: "Not factually correct",
          label: intl.formatMessage({ id: "not_factually_correct" }),
        },
        {
          key: "Didn't fully follow instructions",
          label: intl.formatMessage({ id: "didnt_follow_instructions" }),
        },
        {
          key: "Don't like the style",
          label: intl.formatMessage({ id: "dont_like_style" }),
        },
      ];

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      <GKCard
        className={`p-2 ${styles.feedbackCard}`}
        style={{ background: "#CDE1FF" }}
      >
        <h6>
          {positive
            ? intl.formatMessage({ id: "why_did_you_choose_rating" })
            : intl.formatMessage({ id: "what_can_be_improved" })}
        </h6>
        <div>
          {options.map((o, idx) => (
            <GKCheckbox
              key={idx}
              label={o.label}
              inline={true}
              checked={idx === btnIdx}
              onChange={() => {
                setBtnIdx(idx);
              }}
            />
          ))}
        </div>
        <GKInput
          className="mt-3"
          style={{ minHeight: "90px" }}
          type="textarea"
          value={feedbackStr}
          onChange={(event) => setFeedbackStr(event.target.value)}
          placeholder={intl.formatMessage({
            id: "give_as_much_detail_feedback_ai_assist",
          })}
        />
        <GKButtonGroup align="end">
          <GKButton
            className="mt-2"
            disabled={btnIdx === undefined}
            onClick={() => {
              if (btnIdx !== undefined && options[btnIdx]) {
                segment.track("SubmitChatbotFeedback");
                submitFeedback(options[btnIdx].key, feedbackStr);
              }
            }}
          >
            {intl.formatMessage({ id: "Submit" })}
          </GKButton>
        </GKButtonGroup>
      </GKCard>
    </div>
  );
};
