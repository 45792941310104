// eslint-disable-next-line canonical/filename-no-index
import { IntlProvider } from "react-intl";
import { useUserLocale } from "@granular/fabric3-core";
import { DEFAULT_LOCALE } from "@granular/fabric3-definitions";
import { Chat as ChatApp } from "./Chat.js";
import { Translations } from "./translations/index.js";

export const Chat: React.FunctionComponent = () => {
  const locale = useUserLocale();
  return (
    <IntlProvider
      locale={locale}
      messages={Translations[locale]}
      defaultLocale={DEFAULT_LOCALE}
    >
      <ChatApp />
    </IntlProvider>
  );
};
