// eslint-disable canonical/filename-no-index

/**
 * Create a map of locales to translation maps, which is handed to your apps's
 * I18n provider.
 *
 * Naming locales is a bit annoying since there's no 'standard' for this. So
 * you'll see `en-us`, `en_us`, `en-US`, `en_US`, and so on. We'll try our best
 * to keep this under control by stating that, internally, we use `en-US`. This
 * is what the `useUserLocale` hook will return. Now the naming of the actual
 * keys is another story... 🤪
 *
 * NOTE: Copypasta, update this map by hand. Keep it simple. You'll be OK.
 */

import type { IntlConfig } from "react-intl";
import type {
  SupportedLanguage,
  SupportedLocale,
} from "@granular/fabric3-definitions";

/**
 * We expect you to build and download your app's keys from Lokalise. You'll
 * get them with underscores and we'd like you to be lazy 🌸
 */
import enUS from "./en_US.json";
import enCA from "./en_CA.json";
import frCA from "./fr_CA.json";
import ptBR from "./pt_BR.json";

const translationMap: Record<
  SupportedLocale | Lowercase<SupportedLocale> | SupportedLanguage,
  IntlConfig["messages"]
> = {
  /**
   * This is the 'standard' form of the locale. Note the lowercase-UPPERCASE
   * naming. When developers want to get the user's locale, we ask them to use
   * the `useUserLocale` hook, which returns one of the keys in this block.
   */
  "en-US": enUS,
  "en-CA": enCA,
  "fr-CA": frCA,
  "pt-BR": ptBR,

  /**
   * We need these completely lowercased keys since the User Service stores
   * locales in lower case for some strange, historical reason. Developers
   * really SHOULD be using the `useUserLocale` hook to get the locale (it does
   * more than uppercase the second half of `en-us`) but keep these here
   * defensively.
   */
  "en-us": enUS,
  "en-ca": enCA,
  "fr-ca": frCA,
  "pt-br": ptBR,

  /**
   * Fallbacks. You *must* check with Product and Design before adding or
   * modifying anything here!
   */
  en: enUS,
  fr: frCA,
  pt: ptBR,
};

// TODO: Remove these
export type MessageKeys = keyof typeof enUS;
export const MessageKeys = Object.keys(enUS) as MessageKeys[];

export default translationMap;

// eslint-enable canonical/filename-no-index
