/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * enrollments-svc-lambda
 * OpenAPI spec version: 1.0.0
 */
import {
  z as zod
} from 'zod'

/**
 * Paginates through all operations enrolled in a feature with a pagination key. Cannot guarantee deduplication between pages. Null pagination key means you've found all results.
 * @summary Returns list of all operations enrolled in a given feature
 */
export const getAdminEnrolledOperationsFeatureParams = zod.object({
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING'])
})

export const getAdminEnrolledOperationsFeatureQueryParams = zod.object({
  "nextPageKey": zod.string().optional()
})

export const getAdminEnrolledOperationsFeatureResponse = zod.object({
  "opIds": zod.array(zod.string().uuid()),
  "nextPageKey": zod.string().nullable()
})


/**
 * Fetch operation by id or list with admin/advisor's emai or phone number
 * @summary Retrieve list of connex operations by search
 */
export const getConnexQueryParams = zod.object({
  "bpid": zod.string().optional(),
  "connexid": zod.string().optional(),
  "email": zod.string().optional(),
  "limit": zod.number().optional()
})

export const getConnexResponse = zod.object({
  "connexOpName": zod.string(),
  "connexOpId": zod.string(),
  "granularOpId": zod.string().uuid().nullable(),
  "area":  zod.object({
  "DIRECTED_SCOUTING": zod.number().optional(),
  "AGRONOMY": zod.number().optional(),
  "CARBON": zod.number().optional(),
  "LANDVISOR": zod.number().optional(),
  "CROPVISOR": zod.number().optional(),
  "NITROGEN_MONITORING": zod.number().optional()
}).optional(),
  "bpNames": zod.array(zod.string())
})


/**
 * Creates granular enrollments for every feature available through connex invoices attached to the given connex id
 * @summary Create connex link to granular operation
 */
export const putConnexConnexIdParams = zod.object({
  "connex_id": zod.string()
})

export const putConnexConnexIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const putConnexConnexIdResponseDIRECTEDSCOUTINGPurchasedAreaMin1 = 0;
export const putConnexConnexIdResponseDIRECTEDSCOUTINGTotalPriceMin2 = 0;
export const putConnexConnexIdResponseAGRONOMYPurchasedAreaMin3 = 0;
export const putConnexConnexIdResponseAGRONOMYTotalPriceMin4 = 0;
export const putConnexConnexIdResponseCARBONPurchasedAreaMin5 = 0;
export const putConnexConnexIdResponseCARBONTotalPriceMin6 = 0;
export const putConnexConnexIdResponseLANDVISORPurchasedAreaMin7 = 0;
export const putConnexConnexIdResponseLANDVISORTotalPriceMin8 = 0;
export const putConnexConnexIdResponseCROPVISORPurchasedAreaMin9 = 0;
export const putConnexConnexIdResponseCROPVISORTotalPriceMin10 = 0;
export const putConnexConnexIdResponseNITROGENMONITORINGPurchasedAreaMin11 = 0;
export const putConnexConnexIdResponseNITROGENMONITORINGTotalPriceMin12 = 0;


export const putConnexConnexIdResponse = zod.object({
  "DIRECTED_SCOUTING": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(putConnexConnexIdResponseDIRECTEDSCOUTINGPurchasedAreaMin1),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(putConnexConnexIdResponseDIRECTEDSCOUTINGTotalPriceMin2).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "AGRONOMY": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(putConnexConnexIdResponseAGRONOMYPurchasedAreaMin3),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(putConnexConnexIdResponseAGRONOMYTotalPriceMin4).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "CARBON": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(putConnexConnexIdResponseCARBONPurchasedAreaMin5),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(putConnexConnexIdResponseCARBONTotalPriceMin6).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "LANDVISOR": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(putConnexConnexIdResponseLANDVISORPurchasedAreaMin7),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(putConnexConnexIdResponseLANDVISORTotalPriceMin8).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "CROPVISOR": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(putConnexConnexIdResponseCROPVISORPurchasedAreaMin9),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(putConnexConnexIdResponseCROPVISORTotalPriceMin10).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "NITROGEN_MONITORING": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(putConnexConnexIdResponseNITROGENMONITORINGPurchasedAreaMin11),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(putConnexConnexIdResponseNITROGENMONITORINGTotalPriceMin12).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional()
})


/**
 * Breaks granular enrollments for every feature available through connex invoices attached to the given connex id
 * @summary Break connex link to granular operation
 */
export const deleteConnexConnexIdParams = zod.object({
  "connex_id": zod.string()
})

export const deleteConnexConnexIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const deleteConnexConnexIdResponseDIRECTEDSCOUTINGPurchasedAreaMin13 = 0;
export const deleteConnexConnexIdResponseDIRECTEDSCOUTINGTotalPriceMin14 = 0;
export const deleteConnexConnexIdResponseAGRONOMYPurchasedAreaMin15 = 0;
export const deleteConnexConnexIdResponseAGRONOMYTotalPriceMin16 = 0;
export const deleteConnexConnexIdResponseCARBONPurchasedAreaMin17 = 0;
export const deleteConnexConnexIdResponseCARBONTotalPriceMin18 = 0;
export const deleteConnexConnexIdResponseLANDVISORPurchasedAreaMin19 = 0;
export const deleteConnexConnexIdResponseLANDVISORTotalPriceMin20 = 0;
export const deleteConnexConnexIdResponseCROPVISORPurchasedAreaMin21 = 0;
export const deleteConnexConnexIdResponseCROPVISORTotalPriceMin22 = 0;
export const deleteConnexConnexIdResponseNITROGENMONITORINGPurchasedAreaMin23 = 0;
export const deleteConnexConnexIdResponseNITROGENMONITORINGTotalPriceMin24 = 0;


export const deleteConnexConnexIdResponse = zod.object({
  "DIRECTED_SCOUTING": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(deleteConnexConnexIdResponseDIRECTEDSCOUTINGPurchasedAreaMin13),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(deleteConnexConnexIdResponseDIRECTEDSCOUTINGTotalPriceMin14).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "AGRONOMY": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(deleteConnexConnexIdResponseAGRONOMYPurchasedAreaMin15),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(deleteConnexConnexIdResponseAGRONOMYTotalPriceMin16).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "CARBON": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(deleteConnexConnexIdResponseCARBONPurchasedAreaMin17),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(deleteConnexConnexIdResponseCARBONTotalPriceMin18).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "LANDVISOR": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(deleteConnexConnexIdResponseLANDVISORPurchasedAreaMin19),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(deleteConnexConnexIdResponseLANDVISORTotalPriceMin20).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "CROPVISOR": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(deleteConnexConnexIdResponseCROPVISORPurchasedAreaMin21),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(deleteConnexConnexIdResponseCROPVISORTotalPriceMin22).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "NITROGEN_MONITORING": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(deleteConnexConnexIdResponseNITROGENMONITORINGPurchasedAreaMin23),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(deleteConnexConnexIdResponseNITROGENMONITORINGTotalPriceMin24).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional()
})


/**
 * Retrieves the full payload from Connex for an operation's enrollments and returns it
 * @summary Retrieves the full payload from Connex for an operation's enrollments and returns it
 */
export const getConnexInvoicesQueryParams = zod.object({
  "connexid": zod.string().optional(),
  "sales_year": zod.string().optional()
})

export const getConnexInvoicesResponse = zod.object({
  "OperationId": zod.number(),
  "OperationName": zod.string(),
  "Invoices": zod.array( zod.object({
  "AccountDescription": zod.string(),
  "BusinessPartners": zod.array( zod.object({
  "BusinessPartnerCountry": zod.string(),
  "BusinessPartnerEmail": zod.string().nullable(),
  "BusinessPartnerId": zod.number(),
  "BusinessPartnerName": zod.string(),
  "BusinessPartnerPrimaryPhoneNumber": zod.string(),
  "BusinessPartnerSharePercent": zod.number(),
  "TransactionType": zod.array( zod.object({
  "TotalDollars": zod.number(),
  "TotalUnits": zod.number(),
  "TransactionTypeName": zod.string()
}))
})),
  "InvoiceId": zod.number(),
  "InvoiceType": zod.string(),
  "SalesAgencyId": zod.number()
})),
  "Agencies": zod.array(zod.array( zod.object({
  "AssociateSeller": zod.array( zod.object({
  "AssociateSellerEmail": zod.string(),
  "AssociateSellerId": zod.number(),
  "AssociateSellerName": zod.string(),
  "IsCSA": zod.string()
})),
  "IsCSA": zod.string(),
  "PrimarySalesRepId": zod.number(),
  "PrimarySalesRepName": zod.string(),
  "SalesAgencyCountryId": zod.string(),
  "SalesAgencyId": zod.number(),
  "SalesAgencyName": zod.string(),
  "SalesAgencyRegionId": zod.string(),
  "SalesAgencyTerritoryCode": zod.string(),
  "SalesAgencyType": zod.string(),
  "primarySalesRepEmail": zod.string()
}))).optional()
})


/**
 * Idempotent creation of multiple field enrollments. Request body has array of fieldIds and one feature.
 * @summary Creates set of field enrollments
 */
export const postEnrollmentFieldHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postEnrollmentFieldBody = zod.object({
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "fieldIds": zod.array(zod.string().uuid()).min(1),
  "enrollmentDate": zod.string().datetime().nullish()
})

export const postEnrollmentFieldResponse = zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})


/**
 * Lists all field enrollments for an operation
 * @summary Lists all field enrollments for an operation
 */
export const getEnrollmentFieldHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getEnrollmentFieldResponse = zod.object({
  "DIRECTED_SCOUTING": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})).min(1).optional(),
  "AGRONOMY": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})).min(1).optional(),
  "CARBON": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})).min(1).optional(),
  "LANDVISOR": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})).min(1).optional(),
  "CROPVISOR": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})).min(1).optional(),
  "NITROGEN_MONITORING": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})).min(1).optional()
})


/**
 * Retrieves field enrollment by field id and feature
 * @summary Retrieves field enrollment by field id and feature
 */
export const getEnrollmentFieldFieldIdFeatureParams = zod.object({
  "field_id": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING'])
})

export const getEnrollmentFieldFieldIdFeatureHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getEnrollmentFieldFieldIdFeatureResponse = zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})


/**
 * Update active or enrollmentDate column on field enrollment by field_id/feature
 * @summary Update field enrollment by field_id/feature
 */
export const putEnrollmentFieldFieldIdFeatureParams = zod.object({
  "field_id": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY'])
})

export const putEnrollmentFieldFieldIdFeatureHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const putEnrollmentFieldFieldIdFeatureResponse = zod.object({
  "fieldId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "enrollmentDate": zod.string().nullish()
})


/**
 * Get list of operation enrollments
 * @deprecated
 * @summary Get list of operation enrollments
 */
export const getV1EnrollmentsQueryParams = zod.object({
  "operation_id": zod.string().uuid(),
  "request_date": zod.string().datetime().nullish(),
  "values": zod.string().nullish()
})

export const getV1EnrollmentsHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV1EnrollmentsResponse = zod.object({
  "list": zod.array( zod.object({
  "id": zod.string().uuid(),
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "entity_id": zod.string().uuid(),
  "status": zod.string(),
  "total_area": zod.number().nullish(),
  "invoice_id": zod.string().uuid().nullish(),
  "start_date": zod.string().nullish(),
  "end_date": zod.string().nullish(),
  "total_price": zod.number().nullish(),
  "source": zod.string().nullish(),
  "currency_code": zod.string().nullish()
}))
})


/**
 * Get list of enrolled entities
 * @deprecated
 * @summary Get list of enrolled entities
 */
export const getV1EnrollmentsEntitiesQueryParams = zod.object({
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "request_date": zod.string().datetime().nullish()
})

export const getV1EnrollmentsEntitiesHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV1EnrollmentsEntitiesResponse = zod.object({
  "entityIds": zod.array(zod.string().uuid())
})


/**
 * Get list of operation enrollments
 * @deprecated
 * @summary Get list of operation enrollments
 */
export const getV2EnrollmentsQueryParams = zod.object({
  "operation_id": zod.string().uuid(),
  "request_date": zod.string().datetime().nullish(),
  "values": zod.string().nullish()
})

export const getV2EnrollmentsHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV2EnrollmentsResponse = zod.object({
  "list": zod.array( zod.object({
  "id": zod.string().uuid(),
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "entity_id": zod.string().uuid(),
  "status": zod.string(),
  "total_area": zod.number().nullish(),
  "invoice_id": zod.string().uuid().nullish(),
  "start_date": zod.string().nullish(),
  "end_date": zod.string().nullish(),
  "total_price": zod.number().nullish(),
  "source": zod.string().nullish(),
  "currency_code": zod.string().nullish()
}))
})


/**
 * Create or update field enrollment
 * @deprecated
 * @summary Create or update field enrollment
 */
export const putV2FieldEnrollmentsHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const putV2FieldEnrollmentsBody = zod.object({
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "field_enrollments": zod.array( zod.object({
  "field_id": zod.string().uuid(),
  "status": zod.string()
}))
})

export const putV2FieldEnrollmentsResponse = zod.object({
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "field_enrollments": zod.array( zod.object({
  "field_id": zod.string().uuid(),
  "status": zod.string()
}))
})


/**
 * Get list of field enrollments
 * @deprecated
 * @summary Get list of field enrollments
 */
export const postV2FieldEnrollmentsHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postV2FieldEnrollmentsBody = zod.object({
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "field_ids": zod.array(zod.string().uuid().nullish()).nullish(),
  "request_date": zod.string().datetime().nullish(),
  "limit": zod.number().nullish(),
  "offset": zod.number().nullish()
})

export const postV2FieldEnrollmentsResponse = zod.object({
  "_meta":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "field_enrollments": zod.array( zod.object({
  "field_id": zod.string().uuid(),
  "status": zod.string()
}))
})


/**
 * Get list of operation enrollments
 * @deprecated
 * @summary Get list of operation enrollments
 */
export const getV3EnrollmentsQueryParams = zod.object({
  "operation_id": zod.string().uuid(),
  "request_date": zod.date().optional(),
  "values": zod.string().optional()
})

export const getV3EnrollmentsHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3EnrollmentsResponse = zod.object({
  "enrollments": zod.array( zod.object({
  "id": zod.string().uuid(),
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "entity_id": zod.string().uuid(),
  "status": zod.string(),
  "total_area": zod.number().optional(),
  "total_used_area": zod.number().optional(),
  "invoice_id": zod.string().uuid().nullish(),
  "start_date": zod.string().datetime().nullish(),
  "end_date": zod.string().datetime().nullish(),
  "total_price": zod.number().nullish(),
  "source": zod.string().nullish(),
  "currency_code": zod.string().nullish()
}))
})


/**
 * Get list of field enrollments
 * @deprecated
 * @summary Get list of field enrollments
 */
export const postV3FieldEnrollmentsHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postV3FieldEnrollmentsBody = zod.object({
  "operation_id": zod.string().uuid(),
  "feature_id": zod.string().uuid(),
  "field_ids": zod.array(zod.string().uuid()).optional(),
  "request_date": zod.string().datetime().optional(),
  "count": zod.number().optional(),
  "limit": zod.number().optional(),
  "offset": zod.number().optional()
})

export const postV3FieldEnrollmentsResponse = zod.object({
  "_meta":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "field_enrollments": zod.array( zod.object({
  "field_id": zod.string().uuid(),
  "status": zod.string()
}))
})


/**
 * Creates a new operation enrollment.

Available Sources:"CONNEX","CHARGEBEE", "DEMO",
"GRANULAR_INTERNAL","GRANULAR_SALES","MKT_PROGRAM_CROP","MKT_PROGRAM_PIONEER",
"PIONEER_SALES","PIONEER_EXCEPTION","SOMOS".

Available Features:
"DIRECTED_SCOUTING","AGRONOMY","CARBON","LANDVISOR","CROPVISOR","NITROGEN_MONITORING"

 * @summary Creates a new operation enrollment.
 */
export const postEnrollmentHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postEnrollmentBodyPurchasedAreaMin27 = 0;
export const postEnrollmentBodyTotalPriceMin28 = 0;


export const postEnrollmentBody = zod.object({
  "enrollmentId": zod.string().uuid().optional(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean().optional(),
  "purchasedArea": zod.number().min(postEnrollmentBodyPurchasedAreaMin27),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(postEnrollmentBodyTotalPriceMin28).nullable()
})

export const postEnrollmentResponsePurchasedAreaMin25 = 0;
export const postEnrollmentResponseTotalPriceMin26 = 0;


export const postEnrollmentResponse = zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(postEnrollmentResponsePurchasedAreaMin25),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(postEnrollmentResponseTotalPriceMin26).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})


/**
 * Lists all enrollments for an operation
 * @summary Lists all enrollments for an operation
 */
export const getEnrollmentQueryParams = zod.object({
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY']).optional(),
  "show_inactive": zod.boolean().optional(),
  "date": zod.date().optional()
})

export const getEnrollmentHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getEnrollmentResponseDIRECTEDSCOUTINGPurchasedAreaMin29 = 0;
export const getEnrollmentResponseDIRECTEDSCOUTINGTotalPriceMin30 = 0;
export const getEnrollmentResponseAGRONOMYPurchasedAreaMin31 = 0;
export const getEnrollmentResponseAGRONOMYTotalPriceMin32 = 0;
export const getEnrollmentResponseCARBONPurchasedAreaMin33 = 0;
export const getEnrollmentResponseCARBONTotalPriceMin34 = 0;
export const getEnrollmentResponseLANDVISORPurchasedAreaMin35 = 0;
export const getEnrollmentResponseLANDVISORTotalPriceMin36 = 0;
export const getEnrollmentResponseCROPVISORPurchasedAreaMin37 = 0;
export const getEnrollmentResponseCROPVISORTotalPriceMin38 = 0;
export const getEnrollmentResponseNITROGENMONITORINGPurchasedAreaMin39 = 0;
export const getEnrollmentResponseNITROGENMONITORINGTotalPriceMin40 = 0;


export const getEnrollmentResponse = zod.object({
  "DIRECTED_SCOUTING": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(getEnrollmentResponseDIRECTEDSCOUTINGPurchasedAreaMin29),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(getEnrollmentResponseDIRECTEDSCOUTINGTotalPriceMin30).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "AGRONOMY": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(getEnrollmentResponseAGRONOMYPurchasedAreaMin31),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(getEnrollmentResponseAGRONOMYTotalPriceMin32).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "CARBON": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(getEnrollmentResponseCARBONPurchasedAreaMin33),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(getEnrollmentResponseCARBONTotalPriceMin34).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "LANDVISOR": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(getEnrollmentResponseLANDVISORPurchasedAreaMin35),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(getEnrollmentResponseLANDVISORTotalPriceMin36).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "CROPVISOR": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(getEnrollmentResponseCROPVISORPurchasedAreaMin37),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(getEnrollmentResponseCROPVISORTotalPriceMin38).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional(),
  "NITROGEN_MONITORING": zod.array( zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(getEnrollmentResponseNITROGENMONITORINGPurchasedAreaMin39),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(getEnrollmentResponseNITROGENMONITORINGTotalPriceMin40).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})).min(1).optional()
})


/**
 * Get enrollment by enrollment ID
 * @summary Get enrollment by enrollment ID
 */
export const getEnrollmentEnrollmentIdParams = zod.object({
  "enrollment_id": zod.string().uuid()
})

export const getEnrollmentEnrollmentIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getEnrollmentEnrollmentIdResponsePurchasedAreaMin41 = 0;
export const getEnrollmentEnrollmentIdResponseTotalPriceMin42 = 0;


export const getEnrollmentEnrollmentIdResponse = zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(getEnrollmentEnrollmentIdResponsePurchasedAreaMin41),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(getEnrollmentEnrollmentIdResponseTotalPriceMin42).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})


/**
 * Update enrollment by enrollment ID
 * @summary Update enrollment by enrollment ID
 */
export const putEnrollmentEnrollmentIdParams = zod.object({
  "enrollment_id": zod.string().uuid()
})

export const putEnrollmentEnrollmentIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const putEnrollmentEnrollmentIdBodyPurchasedAreaMin45 = 0;
export const putEnrollmentEnrollmentIdBodyTotalPriceMin46 = 0;


export const putEnrollmentEnrollmentIdBody = zod.object({
  "enrollmentId": zod.string().uuid().optional(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']).optional(),
  "active": zod.boolean().optional(),
  "purchasedArea": zod.number().min(putEnrollmentEnrollmentIdBodyPurchasedAreaMin45).optional(),
  "startDate": zod.string().optional(),
  "endDate": zod.string().nullish(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']).optional(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "totalPrice": zod.number().min(putEnrollmentEnrollmentIdBodyTotalPriceMin46).nullish()
})

export const putEnrollmentEnrollmentIdResponsePurchasedAreaMin43 = 0;
export const putEnrollmentEnrollmentIdResponseTotalPriceMin44 = 0;


export const putEnrollmentEnrollmentIdResponse = zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(putEnrollmentEnrollmentIdResponsePurchasedAreaMin43),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(putEnrollmentEnrollmentIdResponseTotalPriceMin44).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})


/**
 * Upserts operation enrollment from chargebee
 * @summary Upserts operation enrollment from chargebee
 */
export const postChargebeeEnrollmentHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postChargebeeEnrollmentBodyPurchasedAreaMin49 = 0;
export const postChargebeeEnrollmentBodyTotalPriceMin50 = 0;


export const postChargebeeEnrollmentBody = zod.object({
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(postChargebeeEnrollmentBodyPurchasedAreaMin49),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "totalPrice": zod.number().min(postChargebeeEnrollmentBodyTotalPriceMin50).nullable(),
  "chargebeeSubscriptionId": zod.string(),
  "chargebeeResourceVersion": zod.number()
})

export const postChargebeeEnrollmentResponsePurchasedAreaMin47 = 0;
export const postChargebeeEnrollmentResponseTotalPriceMin48 = 0;


export const postChargebeeEnrollmentResponse = zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(postChargebeeEnrollmentResponsePurchasedAreaMin47),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(postChargebeeEnrollmentResponseTotalPriceMin48).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})


/**
 * Requeue an operation enrollment
 * @summary Requeue an operation enrollment
 */
export const postConnexEnrollmentRequeueBody = zod.object({
  "connex_id": zod.string(),
  "sales_year": zod.string()
})


/**
 * Upserts operation enrollment from connex
 * @summary Upserts operation enrollment from connex
 */
export const postConnexEnrollmentHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postConnexEnrollmentBodyPurchasedAreaMin53 = 0;


export const postConnexEnrollmentBody = zod.object({
  "enrollmentId": zod.string().uuid().optional(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(postConnexEnrollmentBodyPurchasedAreaMin53),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "connexOrgId": zod.string(),
  "connexSalesYear": zod.string()
})

export const postConnexEnrollmentResponsePurchasedAreaMin51 = 0;
export const postConnexEnrollmentResponseTotalPriceMin52 = 0;


export const postConnexEnrollmentResponse = zod.object({
  "enrollmentId": zod.string().uuid(),
  "feature": zod.enum(['DIRECTED_SCOUTING', 'AGRONOMY', 'CARBON', 'LANDVISOR', 'CROPVISOR', 'NITROGEN_MONITORING']),
  "active": zod.boolean(),
  "purchasedArea": zod.number().min(postConnexEnrollmentResponsePurchasedAreaMin51),
  "startDate": zod.string(),
  "endDate": zod.string().nullable(),
  "source": zod.enum(['CONNEX', 'CHARGEBEE', 'DEMO', 'GRANULAR_INTERNAL', 'GRANULAR_SALES', 'PIONEER_SALES', 'PIONEER_EXCEPTION']),
  "totalPrice": zod.number().min(postConnexEnrollmentResponseTotalPriceMin52).nullable(),
  "creatorGranularId": zod.string().uuid().nullish(),
  "connexOrgId": zod.string().nullish()
})


/**
 * Returns the list of enrollment features
 * @summary Returns the list of enrollment features
 */
export const getFeaturesResponse = zod.object({
  "features": zod.array(zod.string())
})


/**
 * Fetch list of operations by various search terms. Only one search term can be provided at a time.

 * @summary Retrieve list of operations by search
 */
export const getOperationsSearchQueryParams = zod.object({
  "email": zod.string().email().optional(),
  "phone_number": zod.string().optional(),
  "operation_id": zod.string().uuid().optional(),
  "granular_id": zod.string().uuid().optional(),
  "connex_id": zod.number().optional(),
  "bpid": zod.number().optional(),
  "limit": zod.number().optional()
})

export const getOperationsSearchResponse = zod.object({
  "operations": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})).optional()
})


/**
 * Writes the connex change notification to SQS to process into sales-aggr-db
 * @deprecated
 * @summary Connex invoice change webhook
 */
export const postV1ConnexInvoiceBody = zod.object({
  "operationId": zod.string(),
  "region": zod.string().optional(),
  "salesYear": zod.string()
})

export const postV1ConnexInvoiceResponse = zod.object({
  "meta":  zod.object({
  "success": zod.boolean(),
  "errors": zod.array( zod.object({
  "errorNumber": zod.number(),
  "errorMessage": zod.string()
})).optional()
}).optional(),
  "responseMsg": zod.string()
})


/**
 * Returns whitelist status based on JWT.
 * @summary Returns whitelist status based on JWT.
 */
export const getWhitelistResponse = zod.object({
  "isWhitelisted": zod.boolean(),
  "isSuperUser": zod.boolean()
})


