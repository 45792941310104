import { useFetchNewTokens } from "../services/auth/auth";
import { trpc as coreQueryClient } from "../services/trpc";
import { useOperatingContext } from "./operatingContext";

function useMutationConfig() {
  const utils = coreQueryClient.useUtils();
  const fetchNewTokens = useFetchNewTokens();
  const { resetLocalStoredContexts } = useOperatingContext();

  const mutationConfig = {
    onSuccess: async () => {
      await fetchNewTokens();

      // We need to invalidate everything because the user's context has changed.
      await utils.invalidate();
      resetLocalStoredContexts();
      //TODO: Fix the local storage issue to allow react state to notify changes so this isn't needed.
      window.location.reload();
    },
  };

  return mutationConfig;
}

export function useStartImpersonation() {
  const mutationConfig = useMutationConfig();

  return coreQueryClient.auth.userImpersonationHandler.useMutation(
    mutationConfig,
  );
}

export function useStopImpersonation() {
  const mutationConfig = useMutationConfig();
  return coreQueryClient.auth.deleteUserImpersonationHandler.useMutation(
    mutationConfig,
  );
}
