import { useMount, useLogout } from "@granular/fabric3-core";

const Logout = () => {
  const logout = useLogout();

  useMount(() => {
    logout().catch((error) => {
      console.error("Logout Error:", error);
    });
  });
  return "";
};

export default Logout;
