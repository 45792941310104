/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * cropvisor-service
 * OpenAPI spec version: 0.0.1.1030
 */
import {
  z as zod
} from 'zod'

/**
 * Fetches dashboard summary for the current user
 * @summary Fill Summary
 */
export const getV1SummaryHeader = zod.object({
  "x-operation": zod.string()
})


/**
 * Fetches dashboard summary for the requested fields
 * @summary Fetch Operation Summary
 */
export const getV1SummaryFieldsQueryParams = zod.object({
  "field_ids": zod.array(zod.string().uuid()).optional()
})

export const getV1SummaryFieldsHeader = zod.object({
  "Authorization": zod.string().optional(),
  "x-operation": zod.string()
})

export const getV1SummaryFieldsResponseTotalFieldAreaMin69 = 0;
export const getV1SummaryFieldsResponseTotalFieldCountMin70 = 0;
export const getV1SummaryFieldsResponseFieldsAreaMin64 = 0;
export const getV1SummaryFieldsResponseFieldsLongitudeMin65 = -180;

export const getV1SummaryFieldsResponseFieldsLongitudeMax66 = 180;
export const getV1SummaryFieldsResponseFieldsLatitudeMin67 = -90;

export const getV1SummaryFieldsResponseFieldsLatitudeMax68 = 90;


export const getV1SummaryFieldsResponse = zod.object({
  "fields": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "fieldName": zod.string(),
  "area": zod.number().min(getV1SummaryFieldsResponseFieldsAreaMin64),
  "cropInformation": zod.string().nullish(),
  "farmId": zod.string().uuid().nullish(),
  "farmName": zod.string().nullish(),
  "status": zod.enum(['SUPPORTED', 'CROP_NOT_SUPPORTED', 'INCOMPLETE']).optional(),
  "recommendationMessageEnum": zod.string().nullish(),
  "recommendation":  zod.object({
  "message": zod.string().nullish(),
  "fieldData":  zod.object({
  "previousCrop": zod.string().nullish(),
  "tillage": zod.string().nullish(),
  "coverCrop": zod.number().nullish(),
  "irrigation": zod.string().nullish(),
  "lbsNitrogen": zod.number().nullish()
}).nullish(),
  "skipped": zod.boolean().nullish(),
  "sprayedDate": zod.date().nullish(),
  "sprayed": zod.boolean().optional(),
  "previewMessage": zod.string().nullish()
}).nullish(),
  "activities": zod.array( zod.object({
  "activityId": zod.string().uuid().nullish(),
  "cropAssignmentId": zod.string().uuid().nullish(),
  "plannedDate": zod.date().nullish(),
  "completedDate": zod.date().nullish(),
  "activitySummaries": zod.array( zod.object({
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.string().nullish(),
  "crop": zod.string().nullish(),
  "error": zod.string().nullish()
})).nullish(),
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.string().nullish(),
  "crop": zod.string().nullish(),
  "cropId": zod.string().uuid().nullish(),
  "earliestPlannedDate": zod.date().nullish(),
  "earliestCompletedDate": zod.date().nullish()
})).nullish(),
  "errors": zod.array(zod.any()),
  "shapeEncoded": zod.string(),
  "thumbnailSvg": zod.string().nullish(),
  "longitude": zod.number().min(getV1SummaryFieldsResponseFieldsLongitudeMin65).max(getV1SummaryFieldsResponseFieldsLongitudeMax66),
  "latitude": zod.number().min(getV1SummaryFieldsResponseFieldsLatitudeMin67).max(getV1SummaryFieldsResponseFieldsLatitudeMax68),
  "assignedCrops": zod.array(zod.string().uuid()),
  "plantDate": zod.string().nullish(),
  "productName": zod.string().nullish(),
  "bestActivity":  zod.object({
  "activityId": zod.string().uuid().nullish(),
  "productId": zod.string().uuid().nullish(),
  "date": zod.date().nullish()
}).optional()
})).optional(),
  "businessCycle": zod.string().uuid().nullable(),
  "operationId": zod.string().uuid(),
  "operationName": zod.string(),
  "totalFieldArea": zod.number().min(getV1SummaryFieldsResponseTotalFieldAreaMin69),
  "totalFieldCount": zod.number().min(getV1SummaryFieldsResponseTotalFieldCountMin70),
  "errors": zod.array(zod.any())
})


/**
 * Fetches dashboard operation summary for the current user
 * @summary Get Operation Summary
 */
export const getV1OperationsSummaryHeader = zod.object({
  "x-operation": zod.string(),
  "Authorization": zod.string().optional()
})

export const getV1OperationsSummaryResponse = zod.object({
  "operations": zod.array( zod.object({
  "operationId": zod.string().uuid(),
  "operationName": zod.string(),
  "totalFieldCount": zod.number().optional(),
  "totalFieldArea": zod.number().optional(),
  "fieldCountSprayNow": zod.number().optional(),
  "fieldAreaSprayNow": zod.number().optional(),
  "fieldCountSpraySoon": zod.number().optional(),
  "fieldAreaSpraySoon": zod.number().optional(),
  "fieldCountSprayed": zod.number().optional(),
  "fieldAreaSprayed": zod.number().optional()
})).optional()
})


/**
 * Get all fields summary for a given operation
 * @summary Get Fields Summary By Operation
 */
export const getV1OperationPathOperationIdFieldsParams = zod.object({
  "path_operation_id": zod.string().uuid().optional()
})

export const getV1OperationPathOperationIdFieldsQueryParams = zod.object({
  "show_supported_only": zod.boolean().optional(),
  "show_skipped": zod.boolean().optional(),
  "limit": zod.number().optional(),
  "offset": zod.number().optional()
})

export const getV1OperationPathOperationIdFieldsHeader = zod.object({
  "Authorization": zod.string().optional()
})

export const getV1OperationPathOperationIdFieldsResponse = zod.object({
  "totalCount": zod.number(),
  "count": zod.number(),
  "next": zod.string().nullish(),
  "data": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "fieldName": zod.string(),
  "farmId": zod.string().uuid().nullish(),
  "farmName": zod.string().nullish(),
  "fieldAcres": zod.number(),
  "productName": zod.string().nullish(),
  "recommendationStatus": zod.string().nullish(),
  "activities": zod.array( zod.object({
  "activityId": zod.string().uuid().nullish(),
  "cropAssignmentId": zod.string().uuid().nullish(),
  "plannedDate": zod.date().nullish(),
  "completedDate": zod.date().nullish(),
  "activitySummaries": zod.array( zod.object({
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.string().nullish(),
  "crop": zod.string().nullish(),
  "error": zod.string().nullish()
})).nullish(),
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.string().nullish(),
  "crop": zod.string().nullish(),
  "cropId": zod.string().uuid().nullish(),
  "earliestPlannedDate": zod.date().nullish(),
  "earliestCompletedDate": zod.date().nullish()
})).nullish(),
  "skipped": zod.boolean(),
  "sprayed": zod.boolean(),
  "sprayedDate": zod.string().nullish(),
  "isSupported": zod.boolean(),
  "status": zod.enum(['SUPPORTED', 'CROP_NOT_SUPPORTED', 'INCOMPLETE']).optional(),
  "bestActivity":  zod.object({
  "activityId": zod.string().uuid().nullish(),
  "productId": zod.string().uuid().nullish(),
  "date": zod.date().nullish()
}).optional(),
  "shapeEncoded": zod.string()
})).optional(),
  "operationId": zod.string().uuid()
})


/**
 * Registers field with cropvisor
 * @summary Register Field
 */
export const postV1FieldRegistrationHeader = zod.object({
  "Authorization": zod.string().optional(),
  "x-operation": zod.string().optional()
})


/**
 * Registers multiple fields with cropvisor
 * @summary Register Fields
 */
export const postV1FieldMultiRegistrationsHeader = zod.object({
  "Authorization": zod.string().optional(),
  "x-operation": zod.string().optional()
})


/**
 * Updates field sprayed status and date
 * @summary Update Field Sprayed Data
 */
export const putV1FieldSprayedParams = zod.object({
  "path_operation_id": zod.string().uuid().optional()
})

export const putV1FieldSprayedHeader = zod.object({
  "Authorization": zod.string().optional()
})

export const putV1FieldSprayedBody = zod.object({
  "fieldId": zod.string().uuid(),
  "sprayedDate": zod.date().nullable(),
  "sprayed": zod.boolean().nullable()
})


/**
 * Updates multiple fields' sprayed status and date
 * @summary Update Multi Field Sprayed Data
 */
export const putV1FieldMultiSprayedParams = zod.object({
  "path_operation_id": zod.string().uuid().optional()
})

export const putV1FieldMultiSprayedHeader = zod.object({
  "Authorization": zod.string().optional()
})


/**
 * Fetches best activity for the requested field
 * @summary Get Best Activity
 */
export const getV1BestActivityPathOperationIdPathFieldIdParams = zod.object({
  "path_operation_id": zod.string().uuid().optional(),
  "path_field_id": zod.string().uuid()
})

export const getV1BestActivityPathOperationIdPathFieldIdHeader = zod.object({
  "Authorization": zod.string().optional()
})

export const getV1BestActivityPathOperationIdPathFieldIdResponse = zod.object({
  "activityId": zod.string().uuid().nullish(),
  "productId": zod.string().uuid().nullish(),
  "date": zod.date().nullish()
})


/**
 * Fetches dashboard summary for the current user
 * @summary Get Summary
 */
export const getV2SummaryQueryLimitMax81 = 1000;


export const getV2SummaryQueryParams = zod.object({
  "limit": zod.number().min(1).max(getV2SummaryQueryLimitMax81).optional(),
  "page": zod.number().min(1).optional()
})

export const getV2SummaryHeader = zod.object({
  "Authorization": zod.string().optional(),
  "x-context": zod.string().optional()
})

export const getV2SummaryResponseDataTotalFieldAreaMin76 = 0;
export const getV2SummaryResponseDataTotalFieldCountMin77 = 0;
export const getV2SummaryResponseDataFieldsAreaMin71 = 0;
export const getV2SummaryResponseDataFieldsLongitudeMin72 = -180;

export const getV2SummaryResponseDataFieldsLongitudeMax73 = 180;
export const getV2SummaryResponseDataFieldsLatitudeMin74 = -90;

export const getV2SummaryResponseDataFieldsLatitudeMax75 = 90;
export const getV2SummaryResponseMetadataCountMin78 = 0;
export const getV2SummaryResponseMetadataTotalCountMin79 = 0;
export const getV2SummaryResponseMetadataTotalPagesMin80 = 0;


export const getV2SummaryResponse = zod.object({
  "data": zod.array( zod.object({
  "fields": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "fieldName": zod.string().nullish(),
  "area": zod.number().min(getV2SummaryResponseDataFieldsAreaMin71),
  "cropInformation": zod.string().nullish(),
  "farmId": zod.string().uuid().nullish(),
  "farmName": zod.string().nullish(),
  "status": zod.enum(['SUPPORTED', 'CROP_NOT_SUPPORTED', 'INCOMPLETE']).optional(),
  "recommendation":  zod.object({
  "message": zod.string().nullish(),
  "fieldData":  zod.object({
  "previousCrop": zod.string().nullish(),
  "tillage": zod.string().nullish(),
  "coverCrop": zod.number().nullish(),
  "irrigation": zod.string().nullish(),
  "lbsNitrogen": zod.number().nullish()
}).nullish(),
  "skipped": zod.boolean().nullish(),
  "sprayedDate": zod.date().nullish(),
  "sprayed": zod.boolean().optional(),
  "previewMessage": zod.string().nullish()
}).optional(),
  "activities": zod.array( zod.object({
  "activityId": zod.string().uuid().nullish(),
  "cropAssignmentId": zod.string().uuid().nullish(),
  "plannedDate": zod.date().nullish(),
  "completedDate": zod.date().nullish(),
  "activitySummaries": zod.array( zod.object({
  "activityId": zod.string().uuid().nullish(),
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.number().nullish(),
  "cropAssignmentId": zod.string().uuid().nullish(),
  "plannedDate": zod.date().nullish(),
  "completedDate": zod.date().nullish(),
  "cropId": zod.string().uuid().nullish(),
  "activitySummaries": zod.array( zod.object({
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.string().nullish(),
  "crop": zod.string().nullish(),
  "error": zod.string().nullish()
})).nullish(),
  "crop": zod.string().nullish(),
  "error": zod.string().nullish()
})).nullish(),
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.number().nullish(),
  "crop": zod.string().nullish(),
  "cropId": zod.string().uuid().nullish(),
  "earliestPlannedDate": zod.date().nullish(),
  "earliestCompletedDate": zod.date().nullish()
})).nullish(),
  "errors": zod.array(zod.any()).optional(),
  "shapeEncoded": zod.string().nullish(),
  "thumbnailSvg": zod.string().nullish(),
  "longitude": zod.number().min(getV2SummaryResponseDataFieldsLongitudeMin72).max(getV2SummaryResponseDataFieldsLongitudeMax73),
  "latitude": zod.number().min(getV2SummaryResponseDataFieldsLatitudeMin74).max(getV2SummaryResponseDataFieldsLatitudeMax75),
  "assignedCrops": zod.array(zod.string().uuid()).nullish(),
  "plantDate": zod.string().nullish(),
  "productName": zod.string().nullish(),
  "bestActivity":  zod.object({
  "activityId": zod.string().uuid().nullish(),
  "productId": zod.string().uuid().nullish(),
  "date": zod.date().nullish()
}).optional()
})).optional(),
  "businessCycle": zod.string().uuid().nullable(),
  "operationId": zod.string().uuid(),
  "operationName": zod.string().nullish(),
  "totalFieldArea": zod.number().min(getV2SummaryResponseDataTotalFieldAreaMin76).optional(),
  "totalFieldCount": zod.number().min(getV2SummaryResponseDataTotalFieldCountMin77).optional(),
  "errors": zod.array(zod.any()).optional()
})),
  "metadata":  zod.object({
  "count": zod.number().min(getV2SummaryResponseMetadataCountMin78),
  "totalCount": zod.number().min(getV2SummaryResponseMetadataTotalCountMin79),
  "totalPages": zod.number().min(getV2SummaryResponseMetadataTotalPagesMin80)
}),
  "links":  zod.object({
  "self": zod.string(),
  "first": zod.string(),
  "prev": zod.string().nullable(),
  "next": zod.string().nullable(),
  "last": zod.string()
})
})


/**
 * Fetches dashboard operation summary for the current user
 * @summary Get Operation Summary
 */
export const getV2OperationsSummaryHeader = zod.object({
  "Authorization": zod.string().optional(),
  "x-context": zod.string().optional()
})

export const getV2OperationsSummaryResponse = zod.object({
  "operations": zod.array( zod.object({
  "operationId": zod.string().uuid(),
  "operationName": zod.string(),
  "totalFieldCount": zod.number().optional(),
  "totalFieldArea": zod.number().optional(),
  "fieldCountSprayNow": zod.number().optional(),
  "fieldAreaSprayNow": zod.number().optional(),
  "fieldCountSpraySoon": zod.number().optional(),
  "fieldAreaSpraySoon": zod.number().optional(),
  "fieldCountSprayed": zod.number().optional(),
  "fieldAreaSprayed": zod.number().optional()
}))
})


/**
 * Get all fields summary for a given operation
 * @summary Get Fields Summary By Operation
 */
export const getV2OperationPathOperationIdFieldsParams = zod.object({
  "path_operation_id": zod.string().uuid().optional()
})

export const getV2OperationPathOperationIdFieldsQueryLimitMax85 = 1000;


export const getV2OperationPathOperationIdFieldsQueryParams = zod.object({
  "limit": zod.number().min(1).max(getV2OperationPathOperationIdFieldsQueryLimitMax85).optional(),
  "page": zod.number().min(1).optional(),
  "show_supported_only": zod.boolean().optional(),
  "show_skipped": zod.boolean().optional()
})

export const getV2OperationPathOperationIdFieldsHeader = zod.object({
  "Authorization": zod.string().optional(),
  "context_id": zod.string().optional()
})

export const getV2OperationPathOperationIdFieldsResponseMetadataCountMin82 = 0;
export const getV2OperationPathOperationIdFieldsResponseMetadataTotalCountMin83 = 0;
export const getV2OperationPathOperationIdFieldsResponseMetadataTotalPagesMin84 = 0;


export const getV2OperationPathOperationIdFieldsResponse = zod.object({
  "data": zod.array( zod.object({
  "fieldId": zod.string().uuid(),
  "fieldName": zod.string(),
  "farmId": zod.string().uuid().nullish(),
  "farmName": zod.string().nullish(),
  "fieldAcres": zod.number(),
  "productName": zod.string().nullish(),
  "recommendationStatus": zod.string().nullish(),
  "activities": zod.array( zod.object({
  "activityId": zod.string().uuid().nullish(),
  "cropAssignmentId": zod.string().uuid().nullish(),
  "plannedDate": zod.date().nullish(),
  "completedDate": zod.date().nullish(),
  "activitySummaries": zod.array( zod.object({
  "activityId": zod.string().uuid().nullish(),
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.number().nullish(),
  "cropAssignmentId": zod.string().uuid().nullish(),
  "plannedDate": zod.date().nullish(),
  "completedDate": zod.date().nullish(),
  "cropId": zod.string().uuid().nullish(),
  "activitySummaries": zod.array( zod.object({
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.string().nullish(),
  "crop": zod.string().nullish(),
  "error": zod.string().nullish()
})).nullish(),
  "crop": zod.string().nullish(),
  "error": zod.string().nullish()
})).nullish(),
  "plantingRate": zod.number().nullish(),
  "displayName": zod.string().nullish(),
  "units": zod.string().nullish(),
  "noOfUnits": zod.number().nullish(),
  "crop": zod.string().nullish(),
  "cropId": zod.string().uuid().nullish(),
  "earliestPlannedDate": zod.date().nullish(),
  "earliestCompletedDate": zod.date().nullish()
})).optional(),
  "skipped": zod.boolean(),
  "sprayed": zod.boolean(),
  "sprayedDate": zod.string().nullish(),
  "isSupported": zod.boolean(),
  "status": zod.enum(['SUPPORTED', 'CROP_NOT_SUPPORTED', 'INCOMPLETE']).optional(),
  "bestActivity":  zod.object({
  "activityId": zod.string().uuid().nullish(),
  "productId": zod.string().uuid().nullish(),
  "date": zod.date().nullish()
}).optional(),
  "shapeEncoded": zod.string()
})),
  "metadata":  zod.object({
  "count": zod.number().min(getV2OperationPathOperationIdFieldsResponseMetadataCountMin82),
  "totalCount": zod.number().min(getV2OperationPathOperationIdFieldsResponseMetadataTotalCountMin83),
  "totalPages": zod.number().min(getV2OperationPathOperationIdFieldsResponseMetadataTotalPagesMin84)
}),
  "links":  zod.object({
  "self": zod.string(),
  "first": zod.string(),
  "prev": zod.string().nullable(),
  "next": zod.string().nullable(),
  "last": zod.string()
}),
  "operationId": zod.string().uuid()
})


/**
 * Registers field with cropvisor
 * @summary Register Field
 */
export const postV2FieldRegistrationPathOperationIdParams = zod.object({
  "path_operation_id": zod.string().uuid().optional()
})

export const postV2FieldRegistrationPathOperationIdHeader = zod.object({
  "Authorization": zod.string().optional(),
  "x-context": zod.string().optional()
})

export const postV2FieldRegistrationPathOperationIdResponse = zod.object({
  "operationId": zod.string().uuid(),
  "sprayWindow": zod.string(),
  "fieldId": zod.string().uuid(),
  "fieldData": zod.record(zod.string(), zod.any()),
  "sprayed": zod.boolean(),
  "skipped": zod.boolean()
})


/**
 * Registers multiple fields with cropvisor
 * @summary Register Multiple Fields
 */
export const postV2FieldMultiRegistrationsPathOperationIdParams = zod.object({
  "path_operation_id": zod.string().uuid().optional()
})

export const postV2FieldMultiRegistrationsPathOperationIdHeader = zod.object({
  "Authorization": zod.string().optional(),
  "x-context": zod.string().optional()
})


