import {
  GKButton,
  GKCard,
  GKCardBody,
  GKCardHeader,
  GKCardTitle,
  GKIcon,
  GKIconName,
  GKInput,
  GKLoading,
  GKLoadingType,
} from "@granular/gds";
import {
  getCurrentToken,
  segment,
  useFeatureFlags,
  useOperatingContext,
} from "@granular/fabric3-core";
import React from "react";
import { ChatApi } from "@granular/chatbot-svc-client";
import { Corteva_Pages } from "@granular/authz";
import { useIntl } from "react-intl";
import { datadogRum } from "@datadog/browser-rum";
import { FeedbackBtn } from "./Feedback";
import { ChatHelpModal } from "./HelpModal";
import { InitChatMessage } from "./InitialPrompt";
import styles from "./Chat.module.css";

type ChatData = {
  message: string;
  userEntered: boolean;
};

type ChatMessageProp = {
  data: ChatData;
  showFeedbackBtns: boolean;
  chatId: string | undefined;
};

const RenderMarkdown = React.lazy(() => import("./RenderMarkdown"));

const ChatMessage: React.FunctionComponent<ChatMessageProp> = ({
  data,
  showFeedbackBtns,
  chatId,
}) => {
  const baseStyle: React.CSSProperties = {};
  if (data.userEntered) {
    baseStyle.marginLeft = "25px";
    baseStyle.marginRight = "5px";
    baseStyle.background = "#CDE1FF";
  } else {
    baseStyle.marginRight = "25px";
  }

  if (data.userEntered) {
    return (
      <GKCard style={baseStyle} className={styles.chatMessage}>
        {data.message}
      </GKCard>
    );
  }

  return (
    <GKCard style={baseStyle} className={styles.chatMessage}>
      <RenderMarkdown data={data.message} />
      {showFeedbackBtns && chatId && <FeedbackBtn chatId={chatId} />}
    </GKCard>
  );
};

export const Chat: React.FunctionComponent = () => {
  const [chatMessages, setChatMessages] = React.useState<ChatData[]>([]);
  const [fetchingResponse, setFetchingReponse] = React.useState<boolean>(false);
  const [showHelp, setShowHelp] = React.useState<boolean>(false);
  const [showChat, setShowChat] = React.useState<boolean>(false);
  const [chatId, setChatId] = React.useState<string>();
  const [currentMessageString, setCurrentMessageString] =
    React.useState<string>("");
  const chatContainer = React.useRef<HTMLDivElement>(null);
  const latestQuestion = React.useRef<HTMLDivElement>(null);
  const latestAnswer = React.useRef<HTMLDivElement>(null);
  const bottomOfChatWindow = React.useRef<HTMLDivElement>(null);
  const textArea = React.useRef<HTMLDivElement>(null);
  const [questionPosition, setQuestionPosition] = React.useState<number>();
  const [autoScrolling, setAutoscrolling] = React.useState<boolean>(true);
  const [showFeedbackBtns, setShowFeedbackBtns] =
    React.useState<boolean>(false);
  const [containerWidth, setContainerWidth] = React.useState<number>(500);
  const intl = useIntl();
  const { userCan } = useOperatingContext();
  const flags = useFeatureFlags();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const disableChatForGrowers =
    flags["app-chatbot-disable-for-growers"] ?? false;

  React.useEffect(() => {
    // Scrolls the chat window to the latest question any time the
    // chat messages change. Once we've scrolled the question to
    // the top of the chat window, stop trying to scroll.
    const questionTop = Math.floor(questionPosition ?? 9999);
    const chatWindowTop = chatContainer.current?.getBoundingClientRect().y ?? 0;

    if (fetchingResponse && autoScrolling) {
      latestQuestion.current?.scrollIntoView({ behavior: "smooth" });
      setQuestionPosition(latestQuestion.current?.getBoundingClientRect().y);
    }

    if (autoScrolling && questionTop <= chatWindowTop) {
      setAutoscrolling(false);
    }
  }, [autoScrolling, chatMessages, fetchingResponse, questionPosition]);

  React.useEffect(() => {
    // When the chat container resizes, update the width of the
    // loading bar
    if (chatContainer.current) {
      setContainerWidth(chatContainer.current.offsetWidth);
    }

    // Scrolls the chat window to the bottom at the start of message
    // streaming and if the complete answer and question will fit in
    // the entire visible chat window.
    const chatWindowHeight = chatContainer.current?.offsetHeight ?? 0;
    const totalHeight = latestAnswer.current?.offsetHeight ?? 0;

    if (
      fetchingResponse ||
      (!fetchingResponse && totalHeight < chatWindowHeight)
    ) {
      bottomOfChatWindow.current?.scrollIntoView({ behavior: "smooth" });
      setQuestionPosition(undefined);
      setAutoscrolling(true);
    }

    if (!fetchingResponse) {
      // There's probably a better way to do this
      const questionEntry =
        textArea.current?.children.namedItem("questionEntry");

      if (questionEntry) {
        (questionEntry as HTMLElement).focus();
      }
    }
  }, [fetchingResponse]);

  const toggleChat = () => {
    if (!showChat) {
      segment.track("ChatbotOpen");
    }
    setShowChat(!showChat);
  };

  const asyncQuestion = async (question: string) => {
    const accessToken = getCurrentToken();
    segment.track("ChatbotAskQuestion");
    setShowFeedbackBtns(false);
    setChatMessages([
      ...chatMessages,
      { message: question, userEntered: true },
    ]);
    const chatBody = {
      chatId,
      message: question,
    };
    if (chatId === undefined) {
      const chatIdResp = await ChatApi.getInitChatChatInitGet({
        authToken: `Bearer ${accessToken}`,
      });
      setChatId(chatIdResp.chatId);
      chatBody.chatId = chatIdResp.chatId;
    }
    const respChatMessage: ChatData = {
      message: "",
      userEntered: false,
    };
    fetch("/api/chatbot-svc/chat/streaming", {
      headers: {
        authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(chatBody),
      method: "POST",
    })
      .then((resp) => {
        const reader = resp.body
          ?.pipeThrough(new TextDecoderStream())
          .getReader();
        reader
          ?.read()
          .then(function parseResp({ done, value }): void | Promise<void> {
            if (done) {
              setFetchingReponse(false);
              return;
            }
            respChatMessage.message = respChatMessage.message + value;
            setChatMessages([
              ...chatMessages,
              { message: question, userEntered: true },
              respChatMessage,
            ]);
            return reader
              .read()
              .then(parseResp)
              .catch((error) => {
                throw error;
              });
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
    // scrollToLastChatMessage();
    // The below code is nicer, but not supported by safari browser... 👎
    // const resp = await fetch("/api/chatbot-svc/chat/streaming", {
    //   headers: {
    //     authorization: `Bearer ${accessToken}`,
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(chatBody),
    //   method: "POST",
    // });
    // const stream = resp.body?.pipeThrough(new TextDecoderStream());
    // // @ts-expect-error TS isn't properly checking these from what I can tell
    // for await (const chunk of stream) {
    //   setFetchingReponse(false);
    //   respChatMessage.message = respChatMessage.message + chunk;
    //   setChatMessages([
    //     ...chatMessages,
    //     { message: question, userEntered: true },
    //     respChatMessage,
    //   ]);
    //   scrollToLastChatMessage();
    // }
    // scrollToLastChatMessage();
  };

  const askQuestion = (question: string) => {
    setFetchingReponse(true);
    asyncQuestion(question)
      .then(() => {
        setShowFeedbackBtns(true);
      })
      .catch((error) => {
        console.error(error);
        datadogRum.addError(error);
        setChatMessages([
          ...chatMessages,
          { message: question, userEntered: true },
          { message: "Server failed to respond...", userEntered: false },
        ]);
      });
  };

  const submitQuestion = () => {
    if (currentMessageString.trim() === "") {
      console.log("No question to submit!");
    } else {
      askQuestion(currentMessageString);
      setCurrentMessageString("");
    }
  };

  const assistantName = <span className={styles.aiName}>CARL</span>;

  const renderChatContent = () => (
    <>
      <ChatHelpModal open={showHelp} toggle={() => setShowHelp(false)} />
      <GKButton
        id="toggle-chat-1"
        color="primary"
        className={styles.chatButton}
        onClick={toggleChat}
      >
        <GKIcon
          name={
            showChat
              ? GKIconName.KeyboardArrowRight
              : GKIconName.KeyboardArrowLeft
          }
        ></GKIcon>
        <span className={styles.rotate}>{assistantName}</span>
      </GKButton>
      {showChat && (
        <GKCard className={styles.chatContainer}>
          <GKCardHeader style={{ backgroundColor: "#1460CC", color: "white" }}>
            <GKCardTitle className="d-flex my-0">
              <div className="mr-auto d-flex align-items-center">
                <span>
                  {intl.formatMessage({ id: "project" })} {assistantName}
                </span>
              </div>
              <div>
                <GKButton
                  color="primary"
                  onClick={() => setShowHelp(true)}
                  className="px-1"
                >
                  <GKIcon name={GKIconName.HelpOutline} />
                </GKButton>
                <GKButton color="primary" onClick={toggleChat} className="px-1">
                  <GKIcon name={GKIconName.Clear} />
                </GKButton>
              </div>
            </GKCardTitle>
          </GKCardHeader>
          <GKCardBody style={{ height: "498px", background: "#f7f7f7" }}>
            <div
              ref={chatContainer}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                position: "absolute",
                top: "65px",
                bottom: "140px",
                width: "-webkit-fill-available",
              }}
            >
              <InitChatMessage handleCannedPrompt={askQuestion} />
              {chatMessages.map((m, idx) => (
                <>
                  {m.userEntered && (
                    <div key={`question-${idx}`} ref={latestQuestion}>
                      <ChatMessage
                        data={m}
                        chatId={chatId}
                        showFeedbackBtns={
                          idx === chatMessages.length - 1 &&
                          !m.userEntered &&
                          showFeedbackBtns
                        }
                      />
                    </div>
                  )}
                  {!m.userEntered && (
                    <div key={`answer-${idx}`} ref={latestAnswer}>
                      <ChatMessage
                        data={m}
                        chatId={chatId}
                        showFeedbackBtns={
                          idx === chatMessages.length - 1 &&
                          !m.userEntered &&
                          showFeedbackBtns
                        }
                      />
                    </div>
                  )}
                </>
              ))}
              <div ref={bottomOfChatWindow}></div>
            </div>
            <div
              ref={textArea}
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <GKInput
                name="questionEntry"
                type="textarea"
                disabled={fetchingResponse}
                placeholder={intl.formatMessage({ id: "ask_question_here" })}
                style={{
                  minHeight: "130px",
                  borderTop: "1px #E0E0E0 solid",
                }}
                value={currentMessageString}
                onChange={(event) => {
                  setCurrentMessageString(event.target.value);
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    submitQuestion();
                  }
                }}
              />
              <GKLoading
                isLoading={fetchingResponse}
                overlay={false}
                type={GKLoadingType.Bar}
                style={{ top: 0, right: 0, left: 0, position: "absolute" }}
                progressBarProps={{ height: 2, width: containerWidth }}
              />
            </div>
            <GKButton
              disabled={currentMessageString === ""}
              style={{
                position: "absolute",
                right: 0,
                width: "35px",
                bottom: "90px",
              }}
              color={"link"}
              onClick={submitQuestion}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                id="Layer_1"
                fill="white"
                stroke="#1460CC"
                strokeWidth="2px"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="15 21 10.64 13.36 3 9 21 3 15 21" />
                <line x1="11" y1="13" x2="16" y2="8" />
              </svg>
            </GKButton>
          </GKCardBody>
        </GKCard>
      )}
    </>
  );

  if (disableChatForGrowers) {
    return userCan("view", Corteva_Pages.corteva_page) ? (
      renderChatContent()
    ) : (
      <></>
    );
  } else {
    return renderChatContent();
  }
};
