import type { SingleContext } from "@granular/fabric3-core";
import { useOperatingContext } from "@granular/fabric3-core";
import {
  GKAutoComplete,
  GKIconName,
  GKLoadingPlaceholder,
  Grouping,
} from "@granular/gds";

export const ContextSwitcher: React.FC = () => {
  const { allContexts, contextSelected, setContextSelected, isLoading } =
    useOperatingContext();

  const filterFn = (data: SingleContext, filter: string): boolean => {
    return data.agencyName
      .toLocaleLowerCase()
      .startsWith(filter.toLocaleLowerCase());
  };

  return isLoading ? (
    <GKLoadingPlaceholder className="w-100" />
  ) : (
    <div className="mb-0">
      <GKAutoComplete<SingleContext>
        selectedItem={contextSelected}
        itemToString={(item) => item.agencyName}
        filterFunction={filterFn}
        data={[new Grouping("Contexts", allContexts)]}
        icon={GKIconName.Search}
        onSelection={(selectedContext) => {
          setContextSelected(selectedContext);
        }}
        placeholder="Select Context"
      />
    </div>
  );
};

export default ContextSwitcher;
