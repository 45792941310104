import type { RPCRouter } from "@granular/fabric3-server";
import { createTRPCReact } from "@trpc/react-query";

/**
 * This is the name we use internally in the Core. Just being explicit about
 * the tech we use.
 */

export const trpc = createTRPCReact<RPCRouter>();

/**
 * This is the name we have our developers use. They don't care that it's tRPC,
 * and it's something they're used to: Follows the Old Fabric paradigm of "I
 * don't care _how_ you implemented some React Query-wrapper around some
 * `fetch` thingie that interrogates a backend".
 */

export const coreQueryClient = trpc;
