/**
 * TODO: What does this do?
 */

import { useMount } from "@granular/fabric3-core";
import type { FabricApp } from "@granular/fabric3-definitions";
import { useLocation, useNavigate } from "react-router-dom";
import { IntRange } from "type-fest";

export const HAS_LOGGED_IN = "has-already-logged-in";
export const TRUE = "true";

type DayRange = IntRange<1, 32>;
type MonthRange = IntRange<1, 13>;
export type CutoffDate = `${MonthRange}/${DayRange}`;
export type PathsAndCutoffDates = Array<{ path: string; date: CutoffDate }>;

const defaultPath = "data/dashboard";

/**
 * Given a Date it returns the ordinal number of the day of the year
 * e.g. the 2nd of May is 122, or 123 on a leap year.
 */
const getNumberDayOfTheYear = (now: Date): number => {
  const start = new Date(now.getFullYear(), 0, 0);
  const diff =
    now.valueOf() -
    start.valueOf() +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000; // to account for daylight savings.
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  return day;
};

/**
 * Returns a list with the paths for each season and the ordinal for the
 * last day of each season. Year value has to be dynamic to account
 * for leap years.
 */
const getSeasonCutoffDates = (
  pathsAndCutoffDates: PathsAndCutoffDates,
): Array<[string, number]> => {
  const year = new Date().getFullYear();
  const seasons: Array<[string, number]> = pathsAndCutoffDates.map(
    (pathAndDate) => {
      const cutoffDateOrdinal = getNumberDayOfTheYear(
        new Date(`${pathAndDate.date}/${year}`),
      );
      return [pathAndDate.path, cutoffDateOrdinal];
    },
  );
  return seasons.sort((a, b) => (a[1] < b[1] ? -1 : 1));
};

/**
 * Calculates the moment of the year and returns the expected path
 * for that particular period.
 */
const getLandingPageForCurrentSeason = (
  pathsAndCutoffDates: PathsAndCutoffDates,
): string => {
  const seasons = getSeasonCutoffDates(pathsAndCutoffDates);
  const now = getNumberDayOfTheYear(new Date());
  const seasonTuple = seasons.find((s) => s[1] >= now);
  const path = seasonTuple ? seasonTuple[0] : defaultPath;
  return path;
};

/**
 * Works only on a fresh login.
 *
 * Given the list of available SidebarApps and a list of objects containing the desired path
 * and the ending date for that particular season (without the year value), it redirects
 * the user to the expected landing page according to the time of the year
 *
 * @param availableApps - TODO
 * @param pathsAndCutoffDates - TODO
 */
export const useLandingPageByDateOnFreshLogin = (
  availableApps: FabricApp[],
  pathsAndCutoffDates: PathsAndCutoffDates,
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const landingPageOnFreshLogin =
    getLandingPageForCurrentSeason(pathsAndCutoffDates);

  const availablePaths = availableApps.map((app) => app.to);

  const availableLandingPage = availablePaths?.includes(landingPageOnFreshLogin)
    ? landingPageOnFreshLogin
    : defaultPath;

  /**
   * Redirects the user to the page we want them to see upon a fresh login,
   * it will not redirect them again until they log out by themselves
   * or their session expires.
   */

  useMount(() => {
    sessionStorage.setItem(HAS_LOGGED_IN, TRUE);
    // Only redirect if a specific path was not requested.
    // This avoids redirecting to availableLandingPage when user is trying to direct access a specific app like /data
    if (location.pathname !== "/") return;
    navigate(availableLandingPage);
  });
};
