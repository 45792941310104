import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  PopupRequest,
  SilentRequest,
  SsoSilentRequest,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  LOCAL_STORAGE_USER_CREDS_REFRESH_IN_MS,
  parseJwt,
  JwtPayload,
} from "@granular/fabric3-definitions";
import { getEnvironment } from "../../helpers/environment";
import { useLocalStorage } from "../../hooks/localStorage";

const LOCAL_STORAGE_ACCESS_KEY = "corteva-access-token";
const DEV_SSO_URL = "https://new.dev.sso.granular.ag";
const TEST_SSO_URL = "https://new.test.sso.granular.ag";
const PROD_SSO_URL = "https://new.sso.granular.ag";

// Known SSO Client IDs & their app identifiers.
// Note: this is less about security (the APIs can manage that) and more because Azure in no way exposes the app identifier without a separate request.
const KNOWN_SSO_CLIENTS: Map<string, string> = new Map<string, string>([
  [
    "874617ca-0c03-4ea4-acd5-11ba717e609e",
    "https://cortevadbpsandbox.onmicrosoft.com/beta.b2c.granularinsights",
  ],
  ["b15c7f6d-1162-4f5c-a7ea-f5ea18c5ba81", "api://beta.granularinsights"],
  [
    "9ce07e52-c1f3-496b-9633-ef5d8a4a56a2",
    "https://CortevaDbpDev.onmicrosoft.com/dev.b2c.granularinsights",
  ],
  ["d81c0885-9a1f-4a12-958d-98da3bd63ce7", "api://dev.granularinsights"],
  [
    "ccf7029c-8944-4483-8054-e7b774d4b1e2",
    "https://AgCompanyB2CDEV.onmicrosoft.com/test.b2c.granularinsights",
  ],
  ["e8d11743-7ee5-4096-9260-b07247006874", "api://test.granularinsights"],
  [
    "d741fed1-2217-495b-8363-1fdc44c26234",
    "https://agcompanyb2c.onmicrosoft.com/b2c.granularinsights",
  ],
  ["cb1d5ab6-ee37-4385-9759-fe47ae3217c7", "api://granularinsights"],
]);

/**
 * Now that we are using msal-browser directly, we have to pull back the curtain a little bit on how we authenticate.
 * We have 5 authorities we interact with (4 B2C environments, 1 B2B Corteva environment).
 * We have 8 client applications that represent our 4 environments (B2C and B2B versions).
 * We have 4 scopes we support: internal (insights), indigo, fieldalytics, and tassel.
 * Finally, if the user has multiple Microsoft accounts signed in, give msal a hint on which you care about.
 *
 * If we provide these 4 parameters to msal, it _should_ be able to authenticate silently in most cases.
 * Since SSO uses interaction with authentication, it is able to collect this information.
 * It will kindly provide these values upon a successful auth request prior to redirect.
 * That way, we can remain silent and only redirect to SSO when interaction is needed.
 *
 * TODO: Bully Corporate IT into enabling Entra External Ids so we can have one authority and client. 😡
 */
type SSOAuthConfig = {
  clientId: string;
  authority: string;
  scope: string;
  loginHint: string;
};

/**
 * This is a subset of the values returned by msal. Feel free to expand as needed. Or not.
 */
export type SSOTokenResponse = {
  accessToken: string;
  idToken: string;
  expiresOn: Date | null;
  //flattened values from idTokenClaims
  name: string;
  family_name: string;
  given_name: string;
};

type IdTokenClaims = {
  name: string;
  family_name: string;
  given_name: string;
};

export const fetchNewTokens = async (persistTokens: (tokens: string) => void) =>
  await fetchTokens(persistTokens, true);

export const initialize = async (persistTokens: (tokens: string) => void) => {
  const tokens = await fetchTokens(persistTokens);
  if (tokens) {
    completeAuthentication(persistTokens, tokens);

    return true;
  }

  return false;
};

export const useToken = () =>
  useLocalStorage(LOCAL_STORAGE_ACCESS_KEY, "", false);

const completeAuthentication = (
  persistTokens: (tokens: string) => void,
  tokens: SSOTokenResponse,
) => {
  /**
   * Refetch a new set of tokens 10 minutes before they expire so msal does a refresh for us.
   * 10 minutes is arbitrary.
   */
  const tenMinutes = 600_000;
  let delay = tokens.expiresOn
    ? tokens.expiresOn.getTime() - tenMinutes - Date.now()
    : 0;
  if (delay <= 0) {
    delay = LOCAL_STORAGE_USER_CREDS_REFRESH_IN_MS;
  }

  setInterval(() => {
    console.log("refreshing tokens");
    fetchTokens(persistTokens).catch((error) =>
      console.warn(`Error refreshing token. ${JSON.stringify(error)}`),
    );
  }, delay);
};

/**
 * The SSO page interacts with multiple authorities and client ids
 * Upon signing an user in, the SSO page will cache the values used so we can use them later.

 */
let msalInstance: IPublicClientApplication;
let authConfig: SSOAuthConfig;

async function initializeAuth() {
  // Upon a successful sign in, the SSO page will redirect back to the application with an access token as a query param.
  const currentUrl = new URL(window.location.href);
  let accessToken = currentUrl.searchParams.get("accessToken");
  if (accessToken) {
    localStorage.setItem(LOCAL_STORAGE_ACCESS_KEY, accessToken);
    // Clean up the url after caching it.
    currentUrl.searchParams.delete("accessToken");
    window.history.pushState({}, "", currentUrl);

    return accessToken;
  } else {
    accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
  }

  if (accessToken) {
    const tokenClaims = parseJwt<JwtPayload>(accessToken);

    if (tokenClaims.aud && tokenClaims.iss && tokenClaims.scp) {
      const appId = KNOWN_SSO_CLIENTS.get(tokenClaims.aud as string);

      if (appId) {
        // How msal is configured depends on how the user interacted on the SSO page.
        // We will assume they want to use the same config until they manually log out or browse to the SSO page.
        authConfig = {
          clientId: tokenClaims.aud as string,
          authority: tokenClaims.iss,
          scope: `${appId}/${tokenClaims.scp}`,
          // This is overly defensive. In practice, there is no reason any of these should be null.
          loginHint:
            tokenClaims.login_hint ??
            tokenClaims.preferred_username ??
            tokenClaims.email ??
            "",
        };
      }
    }
  }

  if (!authConfig) {
    console.warn(
      "no access token found. Unable to configure msal. Redirecting to SSO",
    );
    window.location.assign(
      `${getSSOUrl()}?redirect_url=${encodeURIComponent(window.location.href)}`,
    );
    return;
  }

  if (!msalInstance) {
    // This bit of boilerplate must match the configuration on the SSO page.
    const msalConfig: Configuration = {
      auth: {
        clientId: authConfig.clientId,
        authority: authConfig.authority,
      },
      cache: {
        cacheLocation: "localStorage",
      },
    };

    msalInstance =
      await PublicClientApplication.createPublicClientApplication(msalConfig);

    // This is mostly a no-op. However, tries to interact with the user and will block
    // msal methods until that user interaction is resolved.
    // Notably, if you quickly click "logout" while the page is still loading.
    const result = await msalInstance.handleRedirectPromise();
    if (result?.account) {
      msalInstance.setActiveAccount(result.account);
    }
  }
}

async function acquireTokenFromCache(
  account: AccountInfo | null,
): Promise<AuthenticationResult | undefined> {
  if (account) {
    try {
      const silentRequest: SilentRequest = {
        account: account,
        scopes: [authConfig.scope],
      };
      return await msalInstance.acquireTokenSilent(silentRequest);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // if user interaction is required, abort the silent authentication flow and redirect to SSO.
        throw error;
      }
    }
  }

  return undefined;
}

async function acquireTokenUsingIframe(
  account: AccountInfo | null,
): Promise<AuthenticationResult | undefined> {
  try {
    const silentRequest: SsoSilentRequest = {
      account: account ?? undefined,
      loginHint: authConfig.loginHint,
      scopes: [authConfig.scope],
    };
    const loginResponse = await msalInstance.ssoSilent(silentRequest);
    msalInstance.setActiveAccount(loginResponse.account);

    return loginResponse;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // if user interaction is required, abort the silent authentication flow and redirect to SSO.
      throw error;
    }
  }

  return undefined;
}

async function acquireTokenUsingPopup(): Promise<
  AuthenticationResult | undefined
> {
  try {
    const popupRequest: PopupRequest = {
      scopes: [authConfig.scope],
      prompt: "none",
    };

    const loginResponse = await msalInstance.acquireTokenPopup(popupRequest);
    msalInstance.setActiveAccount(loginResponse.account);

    return loginResponse;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // if user interaction is required, abort the silent authentication flow and redirect to SSO.
      throw error;
    }
  }

  return undefined;
}

async function acquireTokenUsingRedirect(scope: string, loginHint: string) {
  await msalInstance.acquireTokenRedirect({
    scopes: [scope],
    loginHint: loginHint,
  });
}

function acquireTokenUsingInteraction() {
  window.location.assign(
    `${getSSOUrl()}?redirect_url=${encodeURIComponent(window.location.href)}`,
  );
}

/**
 * One way or another returns an access token.
 * If a valid token is cached, that is returned.
 * If an expired token is cached, refreshes the token and returns that.
 * If an invalid token is cache or no token is cached, attempts to sign in the user silently using several methods,
 * so that we can support various browser configurations.
 * If all that fails, we redirect the user to the SSO page.
 * @returns The access token if the user is signed in, otherwise undefined.
 */
async function fetchTokens(
  persistTokens: (tokens: string) => void,
  skipCache = false,
): Promise<SSOTokenResponse | undefined> {
  try {
    const freshToken = await initializeAuth();

    if (freshToken) {
      const tokenClaims = parseJwt<JwtPayload>(freshToken);
      return {
        accessToken: freshToken,
        expiresOn: new Date(tokenClaims.exp!),
        idToken: "",
        name: tokenClaims.name,
        given_name: tokenClaims.given_name,
        family_name: tokenClaims.family_name,
      };
    }

    if (msalInstance) {
      const account =
        msalInstance.getActiveAccount() ??
        msalInstance.getAccountByUsername(authConfig.loginHint);
      const loginResponse =
        // Attempt 1: Try and read the token from the cache.
        // This request will automatically refresh the token if necessary.
        // This could fail if token is not cached or if user interaction is required.
        (skipCache ? undefined : await acquireTokenFromCache(account)) ??
        // Attempt 2: Try to sign in using a hidden iframe.
        // This could fail if user interaction is required or if the user has 3rd party cookies blocked.
        (await acquireTokenUsingIframe(account)) ??
        // Attempt 3: Try and sign in with a popup without user interaction
        // This could fail if user interaction is required or if the user has popups blocked.
        (await acquireTokenUsingPopup());

      // We did it! return the information to the caller.
      if (loginResponse) {
        const idTokenClaims = loginResponse.idTokenClaims as IdTokenClaims;
        const result = {
          accessToken: loginResponse.accessToken,
          expiresOn: loginResponse.expiresOn,
          idToken: loginResponse.idToken,
          name: idTokenClaims?.name,
          given_name: idTokenClaims?.given_name,
          family_name: idTokenClaims?.family_name,
        };

        persistTokens(result.accessToken);

        return result;
      }
    }
  } catch (error) {
    console.error("failed to get a token due to exception", error);
  }

  if (authConfig) {
    // Attempt 4: Redirect to the SSO page but ask to not have the user interact.
    // If user interaction is required, the SSO page will handle it.
    await acquireTokenUsingRedirect(authConfig.scope, authConfig.loginHint);
  } else {
    // msal is telling us that the user session has expired and they want to force the user to
    // validate that they are who they say they are. Nothing we can do here but redirect to SSO.
    acquireTokenUsingInteraction();
  }

  return undefined;
}

const getSSOUrl = (): string => {
  const env = getEnvironment();
  if (env === "development" || env === "local") {
    return DEV_SSO_URL;
  }

  if (env === "test") {
    return TEST_SSO_URL;
  }

  return PROD_SSO_URL;
};

export const logout = async (): Promise<void> => {
  if (!msalInstance || !authConfig) {
    // This is embarrassing... you want to log out but I don't even know who you are. One sec..
    await initializeAuth();
  }

  localStorage.removeItem(LOCAL_STORAGE_ACCESS_KEY);

  const account = msalInstance.getActiveAccount();
  await msalInstance.logout({
    authority: authConfig.authority,
    account: account,
    logoutHint: authConfig.loginHint,
    postLogoutRedirectUri: window.location.origin,
  });
};
