import axios from "axios";
import type { AxiosResponse } from "axios";
import {
  AuthenticationData,
  LOCAL_STORAGE_REFRESH_KEY,
  LOCAL_STORAGE_USER_CREDS_REFRESH_IN_MS,
} from "@granular/fabric3-definitions";
import { getEnvironment } from "../../helpers/environment";
import { useLocalStorage } from "../../hooks/localStorage";

const LOCAL_STORAGE_IDENTITY_KEY = "corteva-identity-token";
const DEV_SSO_URL = "https://dev.sso.granular.ag";
const TEST_SSO_URL = "https://test.sso.granular.ag";
const PROD_SSO_URL = "https://sso.granular.ag";

export const initialize = async (persistTokens: (tokens: string) => void) => {
  let tokens = await tryLocalRefreshToken();
  if (tokens) {
    completeAuthentication(persistTokens, tokens);

    return true;
  }

  tokens = await trySessionCode();
  if (tokens) {
    completeAuthentication(persistTokens, tokens);

    return true;
  }

  window.location.assign(
    `${getSSOUrl()}?url=${encodeURIComponent(window.location.href)}`,
  );

  return false;
};

export const useToken = () => {
  return useLocalStorage(LOCAL_STORAGE_IDENTITY_KEY, "", false);
};

const tryLocalRefreshToken = async (): Promise<AuthenticationData | false> => {
  const token = localStorage.getItem(LOCAL_STORAGE_REFRESH_KEY);

  if (token) {
    try {
      const response = await refreshToken(token);
      if (!response) {
        throw new Error("Response from auth token refresh is undefined");
      }
      return response;
    } catch {
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_KEY);
      return false;
    }
  }

  return false;
};

const trySessionCode = async (): Promise<AuthenticationData | false> => {
  const code = new URLSearchParams(window.location.search).get("code");

  if (code) {
    try {
      const result = await fetchTokens(code);
      if (!result) {
        throw new Error("Problem fetching a refresh token with URL code.");
      }
      return result;
    } catch {
      return false;
    }
  }

  return false;
};

const completeAuthentication = (
  persistAccessToken: (tokens: string) => void,
  tokens: AuthenticationData,
) => {
  persistTokens(persistAccessToken, tokens);

  /**
   * We fetch a new set of tokens every 50 minutes. As of this writing, the ID
   * Token (which we need to call all upstream services) expires after 60
   * minutes but the Refresh Token is valid for a year (yes, you read that
   * right). So every 50 minutes, we just call the Auth Service to exchange the
   * Refresh Token for a fresh set of tokens ✨
   *
   * Why 50 minutes? Nothing magical: it's just a reasonable number that's
   * smaller than 60 minutes. That's it! You could certainly set a more
   * aggressive interval (we used to refresh every FIVE minutes) but... why?
   */
  setInterval(
    (tokens) => {
      tokenRefresher(persistAccessToken, tokens).catch((error) =>
        console.warn(`Error refreshing token. ${JSON.stringify(error)}`),
      );
    },
    LOCAL_STORAGE_USER_CREDS_REFRESH_IN_MS,

    /**
     * Arguments! Did you know you could do this with `setInterval` (instead of
     * passing arguments to the target function via an anonymous function!)
     */
    tokens,
  );
};

/**
 * TODO: This will most likely be secure http-only cookies later. Just keep the
 * persistence mechanism separate until we do our research and figure out the
 * best approach.
 */
const persistTokens = (
  persistAccessToken: (tokens: string) => void,
  tokens: AuthenticationData,
) => {
  localStorage.setItem(LOCAL_STORAGE_REFRESH_KEY, tokens.refreshToken);
  persistAccessToken(tokens.identityToken);
};

const tokenRefresher = async (
  persistAccessToken: (tokens: string) => void,
  tokens: AuthenticationData,
) => {
  let newTokens: AuthenticationData;

  try {
    const result = await refreshToken(tokens.refreshToken);
    if (!result) {
      throw new Error("Problem fetching a refresh token with URL code.");
    }
    newTokens = result;
  } catch {
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_KEY);
    window.location.assign(tokens.logoutUrl);
    return;
  }

  persistTokens(persistAccessToken, newTokens);
};

/**
 * Exchange a refresh token for fresh ID, Access, and Refresh Tokens
 *
 * @param token - Refresh token
 * @returns An `AuthSuccessSpec` object
 */
const refreshToken = async (
  token: string,
): Promise<AuthenticationData | undefined> => {
  const result = await axios.post<
    { token: string },
    AxiosResponse<AuthenticationData>
  >("/api/auth-svc/refresh_legacy", {
    token,
  });
  if (result.status === 200) {
    return result.data;
  }
};

/**
 * Exchange a session code for ID, Access, and Refresh Tokens
 *
 * @param code - Session code
 * @returns An `AuthSuccessSpec` object
 */
const fetchTokens = async (
  code: string,
): Promise<AuthenticationData | undefined> => {
  try {
    const result = await axios.get<AuthenticationData>(
      `/api/auth-svc/issue-token?code=${code}`,
    );
    if (result.status !== 200) {
      throw new Error("Issue-token API call failed.");
    }
    return result.data;
  } catch (error) {
    console.error("Issue Token Error:", error);
    return undefined;
  }
};

const getSSOUrl = (): string => {
  const env = getEnvironment();
  if (env === "development" || env === "local") {
    return DEV_SSO_URL;
  }

  if (env === "test") {
    return TEST_SSO_URL;
  }

  return PROD_SSO_URL;
};

export const logout = async (): Promise<void> => {
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_KEY);
  localStorage.removeItem(LOCAL_STORAGE_IDENTITY_KEY);
  window.location.assign(`${getSSOUrl()}/logout`);

  await Promise.resolve();
};
