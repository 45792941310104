/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * vrs-service
 * OpenAPI spec version: 0.0.1.1125
 */
import {
  z as zod
} from 'zod'

/**
 * Test the worker lambda
 * @summary Vrs Hello World
 */
export const getV3VrsHelloWorldHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * Testing Authentication
 * @summary Test Auth
 */
export const getTestAuthHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * Test Headers
 * @summary Test Headers
 */
export const getTestHeadersHeader = zod.object({
  "x-operation": zod.string().uuid(),
  "optional-header": zod.string().optional()
})


/**
 * Testing Cross-Api Call
 * @summary Get Fields
 */
export const getGetFieldsHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * Export Variable Rate Seeding Rx
 * @summary Export Variable Rate Seeding Prescriptions
 */
export const postV3ExportsVariableRateSeedingExportHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postV3ExportsVariableRateSeedingExportBody = zod.object({
  "records": zod.array(zod.string().uuid()),
  "sendTos":  zod.object({
  "download": zod.boolean(),
  "emailAddresses": zod.array(zod.string()),
  "emailSubject": zod.string(),
  "emailMessage": zod.string(),
  "deereOpsCenter": zod.boolean().optional(),
  "deereWorkPlan": zod.boolean().optional()
}),
  "configurations":  zod.object({
  "machineName": zod.string(),
  "machineDisplayName": zod.string()
}),
  "prescriptionRateType": zod.enum(['VariableRateSeeding']),
  "exportType": zod.enum(['VariableRateSeeding']),
  "mergeExportedFilesPerField": zod.boolean().optional(),
  "includeReport": zod.boolean().optional(),
  "airSeederConversion": zod.array( zod.object({
  "recordId": zod.string().uuid(),
  "productId": zod.string().uuid(),
  "conversionFactor": zod.number()
})).optional(),
  "preferredUnitSystem": zod.enum(['imperial', 'metric']).optional(),
  "includePrimaryVarietiesOnly": zod.boolean().optional()
})

export const postV3ExportsVariableRateSeedingExportResponse = zod.object({
  "exportIds": zod.array(zod.string().uuid())
})


/**
 * Get presigned export report url
 * @summary Get Report Pdf Url
 */
export const getV3ExportsHistoryExportIdExportIdReportUrlParams = zod.object({
  "export_id": zod.string().uuid()
})


/**
 * Get operation exports history
 * @summary Get Export History By Operation
 */
export const getV3ExportsHistoryHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3ExportsHistoryResponse = zod.object({
  "exportsHistory": zod.array( zod.object({
  "atlasExportId": zod.string().uuid().optional().nullable(),
  "exportId": zod.string().uuid(),
  // TODO: Fixi this. Checking for `datetime()` here fails and
  // crashes the whole thing, even though the string is indeed valid
  "exportDate": zod.string(),
  "originalUserEmail": zod.string(),
  "originalUserName": zod.string(),
  "operationId": zod.string().uuid(),
  "machineName": zod.string(),
  "machineDisplayName": zod.string(),
  "status": zod.string().optional(),
  "storageLocation": zod.string().optional().nullable(),
  "presignedUrl": zod.string().optional().nullable(),
  "sendTosEmail": zod.array(zod.string()),
  "sendTosDeere": zod.array(zod.string()),
  "sendTosDownload": zod.array(zod.string()),
  "fieldCount": zod.number(),
  "presignedReportUrl": zod.string().optional().nullable(),
  "reportStatus": zod.string().optional()
}))
})


/**
 * Get field exports history
 * @summary Get Export History By Field
 */
export const getV3ExportsHistoryFieldIdFieldIdParams = zod.object({
  "field_id": zod.string().uuid()
})

export const getV3ExportsHistoryFieldIdFieldIdResponse = zod.object({
  "exportsHistory": zod.array( zod.object({
  "atlasExportId": zod.string().uuid().optional(),
  "exportId": zod.string().uuid(),
  "exportDate": zod.string().datetime(),
  "originalUserEmail": zod.string(),
  "originalUserName": zod.string(),
  "operationId": zod.string().uuid(),
  "machineName": zod.string(),
  "machineDisplayName": zod.string(),
  "status": zod.string().optional(),
  "storageLocation": zod.string().optional(),
  "presignedUrl": zod.string().optional(),
  "sendTosEmail": zod.array(zod.string()),
  "sendTosDeere": zod.array(zod.string()),
  "sendTosDownload": zod.array(zod.string()),
  "fieldCount": zod.number(),
  "presignedReportUrl": zod.string().optional(),
  "reportStatus": zod.string().optional()
}))
})


/**
 * Get export history by export id
 * @summary Get Export History By Export
 */
export const getV3ExportsHistoryExportIdExportIdParams = zod.object({
  "export_id": zod.string().uuid()
})

export const getV3ExportsHistoryExportIdExportIdResponse = zod.object({
  "exportsHistory": zod.array( zod.object({
  "atlasExportId": zod.string().uuid().optional(),
  "exportId": zod.string().uuid(),
  "exportDate": zod.string().datetime(),
  "originalUserEmail": zod.string(),
  "originalUserName": zod.string(),
  "operationId": zod.string().uuid(),
  "machineName": zod.string(),
  "machineDisplayName": zod.string(),
  "status": zod.string().optional(),
  "storageLocation": zod.string().optional(),
  "presignedUrl": zod.string().optional(),
  "sendTosEmail": zod.array(zod.string()),
  "sendTosDeere": zod.array(zod.string()),
  "sendTosDownload": zod.array(zod.string()),
  "fieldCount": zod.number(),
  "presignedReportUrl": zod.string().optional(),
  "reportStatus": zod.string().optional()
}))
})


/**
 * Planting Event Layer
 * @summary Get Event Layer
 */
export const getV3FieldEventsActivityIdPlantingEventLayerParams = zod.object({
  "activity_id": zod.string().uuid()
})

export const getV3FieldEventsActivityIdPlantingEventLayerHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * VRS Migration to 2023 structure
 * @summary Vrs Migration
 */
export const postV3VrsMigrationHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postV3VrsMigrationBody = zod.object({
  "cropYear": zod.number(),
  "migrateStats": zod.boolean().optional(),
  "migrateRecProducts": zod.boolean().optional(),
  "migratePlannedProducts": zod.boolean().optional()
})


/**
 * Update product rate(s) for the specified zone(s).
 * @summary Planting Event Rate Update
 */
export const putV3FieldEventsPlantingActivityIdUpdateRatesParams = zod.object({
  "activity_id": zod.string().uuid()
})

export const putV3FieldEventsPlantingActivityIdUpdateRatesHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const putV3FieldEventsPlantingActivityIdUpdateRatesBodyUpdateRatesMax54 = 1000;


export const putV3FieldEventsPlantingActivityIdUpdateRatesBody = zod.object({
  "productId": zod.string().uuid(),
  "updateRates": zod.array( zod.object({
  "productRate": zod.number(),
  "seedingFeatureId": zod.number().or(zod.string())
})).min(1).max(putV3FieldEventsPlantingActivityIdUpdateRatesBodyUpdateRatesMax54)
})


/**
 * Run Variable Rate Seeding
 * @summary Planting Event Endpoint
 */
export const postV3FieldEventsPlantingHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postV3FieldEventsPlantingBody = zod.object({
  "varietyInputs": zod.array( zod.object({
  "seedId": zod.string().uuid(),
  "cropId": zod.string().uuid(),
  "drylandSettings":  zod.object({
  "seedingCurveType": zod.enum(['Dryland', 'Irrigated']),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number(),
  "rateIncrement": zod.number(),
  "seedCost": zod.number().optional(),
  "grainPrice": zod.number().optional(),
  "standLoss": zod.number().optional(),
  "minYieldTarget": zod.number(),
  "maxYieldTarget": zod.number()
}).or( zod.object({
  "rateIncrement": zod.number(),
  "localSeedingRate": zod.number(),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number()
})),
  "irrigatedSettings":  zod.object({
  "seedingCurveType": zod.enum(['Dryland', 'Irrigated']),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number(),
  "rateIncrement": zod.number(),
  "seedCost": zod.number().optional(),
  "grainPrice": zod.number().optional(),
  "standLoss": zod.number().optional(),
  "minYieldTarget": zod.number(),
  "maxYieldTarget": zod.number()
}).optional(),
  "isBackup": zod.boolean().optional()
})),
  "prescriptionInputs": zod.array( zod.object({
  "activityId": zod.string().uuid(),
  "seedId": zod.string().uuid(),
  "description": zod.string().optional(),
  "seedingOptimizer":  zod.object({
  "type": zod.enum(['SeedingCurveBased']).optional(),
  "preferredUnitSystem": zod.enum(['imperial', 'metric']).optional(),
  "zoneSet": zod.string().uuid().or(zod.enum(['ERU', 'SSURGO', 'UnknownSoils'])),
  "layerReferenceId": zod.string().uuid().optional(),
  "additionalProductIds": zod.array(zod.string().uuid()).optional(),
  "upsertProcess": zod.enum(['default', 'reset', 'add_product']).optional()
}),
  "fieldId": zod.string().uuid(),
  "fieldRevisionId": zod.string().uuid(),
  "eventDate": zod.date().optional(),
  "cropId": zod.string().uuid(),
  "productionCycleId": zod.string().uuid(),
  "treatmentId": zod.string().uuid().optional()
}))
})


/**
 * Re-run Variable Rate Seeding
 * @summary Planting Event Vrs Rerun Endpoint
 */
export const postV3FieldEventsPlantingRerunHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postV3FieldEventsPlantingRerunBody = zod.object({
  "seedId": zod.string().uuid(),
  "productionCycleId": zod.string().uuid(),
  "preferredUnitSystem": zod.enum(['imperial', 'metric']).optional()
})


/**
 * Re-run Variable Rate Seeding for the given field
 * @summary Planting Event Vrs Rerun Endpoint Single Field
 */
export const postV3FieldEventsPlantingRerunFieldsFieldIdParams = zod.object({
  "field_id": zod.string().uuid()
})

export const postV3FieldEventsPlantingRerunFieldsFieldIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const postV3FieldEventsPlantingRerunFieldsFieldIdBody = zod.object({
  "seedId": zod.string().uuid(),
  "productionCycleId": zod.string().uuid(),
  "preferredUnitSystem": zod.enum(['imperial', 'metric']).optional()
})


/**
 * Return the status of all user commands
 * @summary Poll All Commands
 */
export const getV3PollQueryParams = zod.object({
  "parent_command_id": zod.string().uuid().optional()
})

export const getV3PollHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * Return the status for the command with the specified command_id
 * @summary Poll Single Command
 */
export const getV3PollCommandIdParams = zod.object({
  "command_id": zod.string().uuid()
})

export const getV3PollCommandIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3PollCommandIdResponse = zod.object({
  "createdBy": zod.string(),
  "commandId": zod.string().uuid(),
  "parentCommandId": zod.string().uuid().optional(),
  "optionalCorrelationId": zod.string().uuid().optional(),
  "response": zod.string(),
  "metadata": zod.string().optional(),
  "status": zod.string()
})


/**
 * Poll status of operational variety delete process
 * @summary Operation Variety Delete Status
 */
export const getV3SeedingOperationPreferencesDeleteStatusHashKeyJobIdParams = zod.object({
  "hash_key_job_id": zod.string()
})

export const getV3SeedingOperationPreferencesDeleteStatusHashKeyJobIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3SeedingOperationPreferencesDeleteStatusHashKeyJobIdResponse = zod.object({
  "jobId": zod.string().uuid().optional(),
  "hashKey": zod.string().optional(),
  "transactionId": zod.string().uuid().optional(),
  "seedIds": zod.array(zod.string().uuid()).optional(),
  "cropType": zod.string().optional(),
  "cropYear": zod.number().optional(),
  "status": zod.string(),
  "step": zod.string().optional(),
  "statusCode": zod.number().optional(),
  "meta": zod.array( zod.object({
  "activityId": zod.string().uuid(),
  "layerReferenceId": zod.string().uuid(),
  "prescriptionRecords": zod.array( zod.object({
  "recordId": zod.string().uuid(),
  "productId": zod.string().uuid()
})).optional()
})).optional(),
  "errorMessage": zod.string().optional()
})


/**
 * Delete seeding operation preferences
 * @summary Delete Operation Preferences
 */
export const postV3SeedingOperationPreferencesDeleteProductionCycleIdParams = zod.object({
  "production_cycle_id": zod.string().uuid()
})

export const postV3SeedingOperationPreferencesDeleteProductionCycleIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * Seeding Operation Preferences for Corn
 * @summary Get Operation Preferences For Corn By Production Cycle Id
 */
export const getV3SeedingOperationPreferencesCornProductionCycleIdParams = zod.object({
  "production_cycle_id": zod.string().uuid()
})

export const getV3SeedingOperationPreferencesCornProductionCycleIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3SeedingOperationPreferencesCornProductionCycleIdResponse = zod.object({
  "defaults":  zod.object({
  "rateIncrement": zod.number(),
  "seedCost": zod.number(),
  "grainPrice": zod.number(),
  "standLoss": zod.number(),
  "minYieldTarget": zod.number(),
  "maxYieldTarget": zod.number(),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number(),
  "seedingCurveType": zod.enum(['Dryland', 'Irrigated'])
}),
  "preferences": zod.array( zod.object({
  "seedId": zod.string().uuid(),
  "rateIncrement": zod.number(),
  "seedCost": zod.number().optional(),
  "grainPrice": zod.number().optional(),
  "standLoss": zod.number().optional(),
  "minYieldTarget": zod.number(),
  "maxYieldTarget": zod.number(),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number(),
  "seedingCurveType": zod.string().optional()
}))
})


/**
 * Save Seeding Operation Preferences for Corn
 * @summary Save Operation Preferences For Corn
 */
export const postV3SeedingOperationPreferencesCornHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * Seeding Operation Preferences for Soybeans
 * @summary Get Operation Preferences For Soybeans By Production Cycle Id
 */
export const getV3SeedingOperationPreferencesSoybeansProductionCycleIdParams = zod.object({
  "production_cycle_id": zod.string().uuid()
})

export const getV3SeedingOperationPreferencesSoybeansProductionCycleIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3SeedingOperationPreferencesSoybeansProductionCycleIdResponse = zod.object({
  "defaults":  zod.object({
  "rateIncrement": zod.number(),
  "localSeedingRate": zod.number().optional(),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number()
}),
  "preferences": zod.array( zod.object({
  "seedId": zod.string().uuid(),
  "rateIncrement": zod.number(),
  "localSeedingRate": zod.number(),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number()
}))
})


/**
 * Save Seeding Operation Preferences for Soybeans
 * @summary Save Operation Preferences For Soybeans
 */
export const postV3SeedingOperationPreferencesSoybeansHeader = zod.object({
  "x-operation": zod.string().uuid()
})


/**
 * Prescription Preferences
 * @summary Get Prescription Preferences By Business Cycle Id
 */
export const getV3SeedingPrescriptionpreferencesBusinesscyclesBusinessCycleIdParams = zod.object({
  "business_cycle_id": zod.string().uuid()
})

export const getV3SeedingPrescriptionpreferencesBusinesscyclesBusinessCycleIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3SeedingPrescriptionpreferencesBusinesscyclesBusinessCycleIdResponse = zod.object({
  "cornPrescriptionPreferences": zod.array( zod.object({
  "activityId": zod.string().uuid().optional(),
  "recordId": zod.string().uuid().optional(),
  "zoneSetId": zod.string().uuid().optional(),
  "zoneSetRevisionId": zod.string().uuid().optional(),
  "seedId": zod.string().uuid().optional(),
  "rateIncrement": zod.number().optional(),
  "seedCost": zod.number().optional(),
  "grainPrice": zod.number().optional(),
  "standLoss": zod.number().optional(),
  "minYieldTarget": zod.number().optional(),
  "maxYieldTarget": zod.number().optional(),
  "minSeedingRate": zod.number().optional(),
  "maxSeedingRate": zod.number().optional(),
  "drylandYieldGoal": zod.number().optional(),
  "irrYieldGoal": zod.number().optional(),
  "yieldGoalUnitOfMeasure": zod.string().optional(),
  "seedingCurveType": zod.string().optional()
})).optional(),
  "soybeansPrescriptionPreferences": zod.array( zod.object({
  "activityId": zod.string().uuid().optional(),
  "recordId": zod.string().uuid().optional(),
  "zoneSetId": zod.string().uuid().optional(),
  "zoneSetRevisionId": zod.string().uuid().optional(),
  "seedId": zod.string().uuid().optional(),
  "rateIncrement": zod.number().optional(),
  "localSeedingRate": zod.number().optional(),
  "minSeedingRate": zod.number().optional(),
  "maxSeedingRate": zod.number().optional(),
  "drylandYieldGoal": zod.number().optional(),
  "irrYieldGoal": zod.number().optional(),
  "yieldGoalUnitOfMeasure": zod.string().optional()
})).optional()
})


/**
 * Seeding Prescription Stats
 * @summary Get Prescription Stats By Business Cycle Id
 */
export const getV3SeedingPrescriptionstatsBusinesscyclesBusinessCycleIdParams = zod.object({
  "business_cycle_id": zod.string().uuid()
})

export const getV3SeedingPrescriptionstatsBusinesscyclesBusinessCycleIdHeader = zod.object({
  "x-operation": zod.string().uuid()
})

export const getV3SeedingPrescriptionstatsBusinesscyclesBusinessCycleIdResponse = zod.object({
  "stats": zod.array( zod.object({
  "activityId": zod.string().uuid(),
  "recordId": zod.string().uuid(),
  "minCalcSeedingRate": zod.number(),
  "maxCalcSeedingRate": zod.number(),
  "avgCalcSeedingRate": zod.number(),
  "minSeedingRate": zod.number(),
  "maxSeedingRate": zod.number(),
  "avgSeedingRate": zod.number(),
  "calcUnits": zod.number(),
  "units": zod.number()
}))
})


