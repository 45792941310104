import type { Environment } from "@granular/fabric3-definitions";
import { useCountryFromUrl } from "../hooks/useCountryFromUrl";

export const getEnvironment = (): Environment => {
  if (
    window.location.hostname.includes("nginx") ||
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("127.0.0.1")
  ) {
    return "local";
  }
  if (
    window.location.hostname.includes(".dev.") ||
    window.location.hostname.startsWith("dev.")
  ) {
    return "development";
  }
  if (
    window.location.hostname.includes(".test.") ||
    window.location.hostname.startsWith("test.")
  ) {
    return "test";
  }
  return "production";
};

export const useInsightsUrl = () => {
  /**
   * We don't use short names anywhere except for here.
   */
  const SHORT_ENV_MAP: Record<Environment, string> = {
    local: "dev.",
    development: "dev.",
    test: "test.",
    production: "",
  };

  // TODO: ... why?
  return new URL(
    "/",
    `https://${useCountryFromUrl()}.${SHORT_ENV_MAP[getEnvironment()]}insights.granular.ag`,
  );
};
