import { GKLoading, GKLoadingType } from "@granular/gds";

const Loading: React.FC<{
  spinnerType?: GKLoadingType;
}> = ({ spinnerType = GKLoadingType.Spinner }) => (
  <div className="h-100 d-flex justify-content-center">
    <GKLoading type={spinnerType} isLoading />
  </div>
);

export default Loading;
