import React, { Suspense } from "react";
import Segment from "./Segment";
import { coreQueryClient } from "../services/trpc";

/**
 * This wrapper component will try lazy loading all these chunks (rollup
 * handles the chunking because we use the `import` function). But we don't put
 * a loading indicator in the suspense fallback, because we still want the app
 * to function While these chunks are loading. So, react suspense will just
 * render the children without wrapping them in the providers until the chunks
 * are downloaded.
 *
 * This means you cannot use any Datadog features until the chunks created by
 * this React.lazy boundary are downloaded and parsed. So always check to see
 * if the libraries in this wrapper are really loaded.
 */
export const ThirdParty = (props?: React.PropsWithChildren) => {
  /**
   * As more 3rd party scripts and APIs are needed, they should be added here,
   * lazily.
   */
  const Datadog = React.lazy<React.FC<React.PropsWithChildren>>(
    () => import("./DataDog"),
  );

  const { isLoading, isSuccess, data } =
    coreQueryClient.user.profile.useQuery();

  return (
    <Suspense fallback={props?.children}>
      <Datadog />

      {/*
        Unlike the DataDog component, the Segment one will need User Info. This
        is just for starters. It MIGHT be that we'll need to instantiate
        the `analytics` object with more information (like, maybe, the
        Context ID).
      */}
      {!isLoading && isSuccess && (
        <Segment
          id={data.granularId}
          /**
           * Add any other identifying information here.
           *
           * NOTE: Do *NOT* pass any identifying information here! This is per
           * guidance from Product. In the old world, we used send this
           * identifying beacon with this information. This is against
           * corporate policy now.
           */
        >
          {props?.children}
        </Segment>
      )}
    </Suspense>
  );
};
