/**
 * Allow people to set page titles. This is important.
 */

import { HelmetProvider } from "react-helmet-async";

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <HelmetProvider>{children}</HelmetProvider>
);

export default Wrapper;
