import {
  useMount,
  useToken,
  useStopImpersonation,
} from "@granular/fabric3-core";
import { GKButton, GKLoading, GKLoadingType, useAlerts } from "@granular/gds";
import { parseJwt } from "@granular/fabric3-definitions";

export const ImpersonationContext = () => {
  const [token] = useToken();
  const alert = useAlerts();
  const { mutate, isLoading } = useStopImpersonation();

  useMount(() => {
    const jwt = parseJwt(token);
    const impersonatedGranularId =
      jwt?.["custom:granular_impersonation_email"] ??
      jwt?.["custom:granular_impersonation_id"] ??
      "";

    if (impersonatedGranularId) {
      alert.global.info(
        <div className="d-flex">
          <span>You are impersonating user: {impersonatedGranularId}</span>
          <GKButton
            className="ml-auto"
            onClick={() => mutate()}
            disabled={isLoading}
          >
            <GKLoading
              className="mr-2"
              isLoading={isLoading}
              type={GKLoadingType.Spinner}
              inline={true}
              spinnerProps={{ width: 16, inverse: true }}
            />
            Stop impersonating
          </GKButton>
        </div>,
        {
          dismissible: true,
        },
      );
    }
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
