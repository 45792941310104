import { useUserLocale } from "@granular/fabric3-core";
import {
  GKButton,
  GKButtonGroup,
  GKModal,
  GKModalBody,
  GKModalFooter,
  GKModalHeader,
} from "@granular/gds";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

type HelpModalProps = {
  open: boolean;
  chatId?: string;
  toggle: () => void;
};
export const ChatHelpModal: React.FunctionComponent<HelpModalProps> = ({
  open,
  toggle,
}) => {
  const locale = useUserLocale();
  const intl = useIntl();
  const msgs = [
    intl.formatMessage({ id: "ai_assistant" }),
    intl.formatMessage({ id: "this_ai_assistant_references" }),
    intl.formatMessage({ id: "scope_of_queries" }),
    intl.formatMessage({ id: "this_is_an_experimental_ai" }),
    intl.formatMessage({ id: "close" }),
  ];
  const helpCenterQuery = (
    <FormattedMessage
      id="granular_insights_topics"
      values={{
        helpCenterLink: (
          <a
            href={`https://support.insights.granular.ag/hc/${locale.toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "help_center" })}
          </a>
        ),
      }}
    ></FormattedMessage>
  );
  const queries = [
    intl.formatMessage({ id: "yield_data" }),
    intl.formatMessage({ id: "fungicide_use" }),
    intl.formatMessage({ id: "disease_monitoring" }),
    intl.formatMessage({ id: "enlist_weed_control" }),
    helpCenterQuery,
  ];

  return (
    <GKModal isOpen={open} centered={true}>
      <GKModalHeader toggle={toggle}>{msgs[0]}</GKModalHeader>
      <GKModalBody>
        <p>
          {msgs[1]}
          <br />
          <br />
          {msgs[2]}
          {queries.map((x, idx) => (
            <li key={idx}>{x}</li>
          ))}
          <br />
          {msgs[3]}
        </p>
      </GKModalBody>
      <GKModalFooter>
        <GKButtonGroup>
          <GKButton onClick={toggle} color="primary">
            {msgs[4]}
          </GKButton>
        </GKButtonGroup>
      </GKModalFooter>
    </GKModal>
  );
};
