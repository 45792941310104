/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * roles-svc
 * OpenAPI spec version: 1.0.6
 */
import {
  z as zod
} from 'zod'

/**
 * Get the user's operations via email address. The purpose of using the POST method rather than the GET method is for security reasons. Utilizing the request body of the POST, we can better protect the user's information.

 * @summary Fetch bpid and zipcode by email address
 */
export const postV2AarByEmailBody = zod.object({
  "email": zod.string()
})

export const postV2AarByEmailResponse = zod.object({
  "bpid": zod.string(),
  "zipcode": zod.string()
})


/**
 * Get list of users in the operation
 * @summary Get list of users in the operation
 */
export const getV2AdminOperationOperationIdUserParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV2AdminOperationOperationIdUserResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "users": zod.array( zod.object({
  "first": zod.string(),
  "last": zod.string(),
  "email": zod.string().nullable(),
  "phone": zod.string().nullable(),
  "granular_id": zod.string().uuid(),
  "modify_date": zod.string().datetime().optional(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
})
}))
})


/**
 * A way for enrollments team to get a list of operations that have changed since they last synced
 * @summary Get all operations that have been created/updated since the given date
 */
export const getV2AdminOperationUpdateCheckDateParams = zod.object({
  "check_date": zod.string()
})


/**
 * A way for enrollments team to get operation with modify_date
 * @summary Get all operations that have been created/updated since the given date
 */
export const getV2AdminOperationIdUpdateOpIdParams = zod.object({
  "op_id": zod.string().uuid()
})

export const getV2AdminOperationIdUpdateOpIdResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "modify_date": zod.string()
})


/**
 * Deletes a list of operations
 * @summary Delete list of operations
 */
export const deleteV2AdminOperationsBody = zod.object({
  "op_ids": zod.array(zod.string().uuid())
})

export const deleteV2AdminOperationsResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "modify_date": zod.string()
})


/**
 * Delete an operation
 * @summary Delete an operation
 */
export const deleteV2AdminOperationsOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * Get list of corteva team members in the operation
 * @summary Get list of corteva team members in the operation
 */
export const getV2OperationOperationIdCortevaUserParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV2OperationOperationIdCortevaUserResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "users": zod.array( zod.object({
  "first": zod.string(),
  "last": zod.string(),
  "email": zod.string().nullable(),
  "phone": zod.string().nullable(),
  "granular_id": zod.string().uuid(),
  "modify_date": zod.string().datetime().optional(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional(),
  "inviter_id": zod.string().nullish(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
})
}))
})


/**
 * Removes all corteva users from an operation and stores the reason that the user booted the corteva team. Generates a new operation for the corteva team, and permissions them into that new operation
 * @summary Removes corteva team from an operation
 */
export const deleteV2OperationOperationIdCortevaUserParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const deleteV2OperationOperationIdCortevaUserBody = zod.object({
  "allow_copy_operation": zod.boolean(),
  "removal_reason": zod.string()
})


/**
 * After corteva has been booted from an operation, call this endpoint to reverse that boot
 * @summary Repermissions corteva team back to an operation
 */
export const postV2OperationOperationIdCortevaUserParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * Get list of corteva team members ids and last modified
 * @summary Get sync keys
 */
export const getV2OperationOperationIdCortevaUserSyncKeysParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV2OperationOperationIdCortevaUserSyncKeysResponse = zod.object({
  "metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "active": zod.array( zod.object({
  "key": zod.string().uuid(),
  "lastModified": zod.string().datetime().optional()
}))
})


/**
 * Get corteva users whose keys are in the request body
 * @summary Get sync data
 */
export const postV2OperationOperationIdCortevaUserSyncDataParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const postV2OperationOperationIdCortevaUserSyncDataBody = zod.object({
  "keys": zod.array(zod.string().uuid())
})

export const postV2OperationOperationIdCortevaUserSyncDataResponse = zod.object({
  "metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "active": zod.array( zod.object({
  "key": zod.string().uuid(),
  "lastModified": zod.string().datetime().optional()
}))
})


/**
 * Adds a corteva user for the operation. The corteva_type must be one of "sales_rep", "territory_manager", "csa", "field_agronomist", "strategic_account_manager". The operation_id and user_id in body and URL must match.
 * @summary Adds corteva user
 */
export const putV2OperationOperationIdCortevaUserUserIdParams = zod.object({
  "operation_id": zod.string().uuid(),
  "user_id": zod.string().uuid()
})

export const putV2OperationOperationIdCortevaUserUserIdBody = zod.object({
  "corteva_type": zod.enum(['csa', 'field_agronomist', 'sales_rep', 'strategic_account_manager', 'territory_manager']),
  "brand_name": zod.string()
})


/**
 * Deletes the user from the given operation_id
 * @summary Delete user from operation
 */
export const deleteV2OperationOperationIdCortevaUserUserIdParams = zod.object({
  "operation_id": zod.string().uuid(),
  "user_id": zod.string().uuid()
})


/**
 * Get all settings for the provided operation id
 * @summary Get all settings for the provided operation id
 */
export const getV2OperationOperationIdSettingParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV2OperationOperationIdSettingResponse = zod.object({
  "operation_id": zod.string().uuid().optional(),
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
})


/**
 * Updates the settings provided assuming they are valid keys. Can send the whole payload, or just a partial object.
 * @summary Update setting for the provided operation id
 */
export const putV2OperationOperationIdSettingParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const putV2OperationOperationIdSettingBody = zod.object({
  "operation_id": zod.string().uuid(),
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
})

export const putV2OperationOperationIdSettingResponse = zod.object({
  "operation_id": zod.string().uuid(),
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
})


/**
 * Get a list of operations belonging to the user. Pagination is available using the limit and offset query parameters.
 * @summary Get a list of operations belonging to the user
 */
export const getV2OperationQueryParams = zod.object({
  "settings": zod.boolean().optional(),
  "user_perms": zod.boolean().optional(),
  "corteva": zod.string().optional(),
  "search_term": zod.string().optional(),
  "limit": zod.number().optional(),
  "offset": zod.number().optional()
})

export const getV2OperationResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "operations": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
}),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}).optional(),
  "user_permission":  zod.object({
  "roles-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "fields-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "enrollments-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "inputs-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "layers-store": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "activities-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "landvisor": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "financial_permission": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "imagery-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "nitrogen_permission": zod.record(zod.string(), zod.array(zod.string())).optional()
}).optional(),
  "modify_date": zod.string(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional()
}))
})


/**
 * Create an operation
 * @summary Create an operation
 */
export const postV2OperationQueryParams = zod.object({
  "user_perms": zod.boolean().optional()
})

export const postV2OperationBody = zod.object({
  "id": zod.string().uuid().optional(),
  "name": zod.string(),
  "creator_role": zod.enum(['administrator', 'advisor']).optional(),
  "modify_date": zod.string().optional(),
  "created_by_aar": zod.boolean().optional(),
  "country": zod.enum(['US', 'CA'])
})

export const postV2OperationResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
}),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}).optional(),
  "user_permission":  zod.object({
  "roles-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "fields-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "enrollments-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "inputs-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "layers-store": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "activities-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "landvisor": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "financial_permission": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "imagery-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "nitrogen_permission": zod.record(zod.string(), zod.array(zod.string())).optional()
}).optional(),
  "modify_date": zod.string(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional()
})


/**
 * Get operation by id
 * @summary Get operation by id
 */
export const getV2OperationOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV2OperationOperationIdQueryParams = zod.object({
  "settings": zod.boolean().optional(),
  "user_perms": zod.boolean().optional()
})

export const getV2OperationOperationIdResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
}),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}).optional(),
  "user_permission":  zod.object({
  "roles-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "fields-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "enrollments-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "inputs-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "layers-store": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "activities-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "landvisor": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "financial_permission": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "imagery-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "nitrogen_permission": zod.record(zod.string(), zod.array(zod.string())).optional()
}).optional(),
  "modify_date": zod.string(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional()
})


/**
 * Update an operation
 * @summary Update an operation
 */
export const putV2OperationOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const putV2OperationOperationIdQueryParams = zod.object({
  "user_perms": zod.boolean().optional()
})

export const putV2OperationOperationIdBody = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})

export const putV2OperationOperationIdResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
}),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}).optional(),
  "user_permission":  zod.object({
  "roles-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "fields-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "enrollments-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "inputs-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "layers-store": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "activities-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "landvisor": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "financial_permission": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "imagery-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "nitrogen_permission": zod.record(zod.string(), zod.array(zod.string())).optional()
}).optional(),
  "modify_date": zod.string(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional()
})


/**
 * Delete an operation
 * @summary Delete an operation
 */
export const deleteV2OperationOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * gets operations based on the given sync keys.
 * @summary gets operations based on the given sync keys
 */
export const postV2OperationSyncDataQueryParams = zod.object({
  "settings": zod.boolean().optional(),
  "user_perms": zod.boolean().optional(),
  "corteva": zod.string().optional()
})

export const postV2OperationSyncDataBody = zod.object({
  "keys": zod.array(zod.string().uuid())
})

export const postV2OperationSyncDataResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "operations": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
}),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}).optional(),
  "user_permission":  zod.object({
  "roles-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "fields-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "enrollments-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "inputs-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "layers-store": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "activities-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "landvisor": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "financial_permission": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "imagery-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "nitrogen_permission": zod.record(zod.string(), zod.array(zod.string())).optional()
}).optional(),
  "modify_date": zod.string(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional()
}))
})


/**
 * Get a list of operation information based on bpid/zipcode. Will return whether a combination was valid or not, as well as any matching operations.
 * @summary Get a list of operation information based on bpid/zipcode
 */
export const getV2OperationByBpidQueryParams = zod.object({
  "bpid": zod.string().optional(),
  "zipcode": zod.string().optional()
})

export const getV2OperationByBpidResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "is_valid": zod.boolean(),
  "operations": zod.array( zod.object({
  "operation_id": zod.string(),
  "operation_name": zod.string()
}))
})


/**
 * The current user becomes a member of linked operations defined by BPID/zip.
 * @summary Current user becomes a member of operations
 */
export const postV2OperationByBpidQueryParams = zod.object({
  "bpid": zod.string().optional(),
  "zipcode": zod.string().optional()
})

export const postV2OperationByBpidBody = zod.object({
  "base_role": zod.enum(['administrator', 'advisor'])
})

export const postV2OperationByBpidResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "is_valid": zod.boolean(),
  "operations": zod.array( zod.object({
  "operation_id": zod.string(),
  "operation_name": zod.string()
}))
})


/**
 * Returns operation to cloned operation linking and removal information. Returns 404 if linkage not found.
 * @summary Fetch AAR information for the operation
 */
export const getV2OperationOperationIdAarParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV2OperationOperationIdAarResponse = zod.object({
  "operation":  zod.object({
  "third_party_id": zod.string(),
  "cloned_op_id": zod.string().uuid().nullable(),
  "operation_id": zod.string().uuid(),
  "on_farm_key": zod.string(),
  "operation_name": zod.string(),
  "source_system": zod.string(),
  "country": zod.string(),
  "create_date": zod.string().datetime(),
  "modify_date": zod.string().datetime(),
  "status": zod.boolean()
}),
  "removal":  zod.object({
  "removal_reason": zod.string(),
  "cloned_op_id": zod.string().uuid().nullable(),
  "operation_id": zod.string().uuid(),
  "boot_count": zod.number(),
  "allow_copy_data": zod.boolean()
}).optional(),
  "connections": zod.array( zod.object({
  "third_party_id": zod.string(),
  "cloned_op_id": zod.string().uuid().nullable(),
  "operation_id": zod.string().uuid(),
  "on_farm_key": zod.string(),
  "operation_name": zod.string(),
  "source_system": zod.string(),
  "country": zod.string(),
  "create_date": zod.string().datetime(),
  "modify_date": zod.string().datetime(),
  "status": zod.boolean()
})).optional()
})


/**
 * Get set of roles to build and query ACLs across services. This endpoint is intended for use with the `granular_authz` package. New fields in the payload will change how queries need to be written and will not be considered a breaking change. Kyber *HIGHLY* recommends you use the `granular_authz` package to do all the queries and ACL generation since that will be kept up to date with this endpoint
 * @summary Get set of roles
 */
export const getV2OperationOperationIdRoleParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * Get list of users in the operation
 * @summary Get list of users in the operation
 */
export const getV2OperationOperationIdUserParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV2OperationOperationIdUserQueryParams = zod.object({
  "limit": zod.number().optional(),
  "offset": zod.number().optional()
})

export const getV2OperationOperationIdUserResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "users": zod.array( zod.object({
  "first": zod.string(),
  "last": zod.string(),
  "email": zod.string().nullable(),
  "phone": zod.string().nullable(),
  "granular_id": zod.string().uuid(),
  "modify_date": zod.string().datetime().optional(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
})
}))
})


/**
 * Updates the list of users to the roles associated to the business rules provided
 * @summary Update users roles
 */
export const putV2OperationOperationIdUserParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * Deletes the user from the given operation_id
 * @summary Delete user from operation
 */
export const deleteV2OperationOperationIdUserParams = zod.object({
  "operation_id": zod.string().uuid(),
  "user_id": zod.string().uuid()
})


/**
 * Get a list of operations belonging to the user. Pagination is available using the limit and offset query parameters.
 * @summary Get a list of operations belonging to the user
 */
export const getV3OperationsQueryParams = zod.object({
  "search_term": zod.string().optional(),
  "limit": zod.number().optional(),
  "offset": zod.number().optional()
})

export const getV3OperationsResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "operations": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
}),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}).optional(),
  "user_permission":  zod.object({
  "roles-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "fields-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "enrollments-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "inputs-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "layers-store": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "activities-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "landvisor": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "financial_permission": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "imagery-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "nitrogen_permission": zod.record(zod.string(), zod.array(zod.string())).optional()
}).optional(),
  "modify_date": zod.string(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional(),
  "is_corteva": zod.boolean(),
  "country": zod.string()
}))
})


/**
 * Get the user's operations via email address. The purpose of using the POST method rather than the GET method is for security reasons. Utilizing the request body of the POST, we can better protect the user's information.

 * @summary Fetch bpid and zipcode by email address
 */
export const postV4AarByEmailBody = zod.object({
  "email": zod.string()
})

export const postV4AarByEmailResponse = zod.object({
  "bpid": zod.string(),
  "zipcode": zod.string()
})


/**
 * Get list of users in the operation
 * @summary Get list of users in the operation
 */
export const getV4AdminOperationsOperationIdUserParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV4AdminOperationsOperationIdUserResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "users": zod.array( zod.object({
  "first": zod.string(),
  "last": zod.string(),
  "email": zod.string().nullable(),
  "phone": zod.string().nullable(),
  "granular_id": zod.string().uuid(),
  "modify_date": zod.string().optional(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
})
}))
})


/**
 * A way for enrollments team to get a list of operations that have changed since they last synced
 * @summary Get all operations that have been created/updated since the given date
 */
export const getV4AdminOperationsUpdateCheckDateParams = zod.object({
  "check_date": zod.string()
})


/**
 * A way for enrollments team to get operation with modify_date
 * @summary Get all operations that have been created/updated since the given date
 */
export const getV4AdminOperationIdUpdateOpIdParams = zod.object({
  "op_id": zod.string().uuid()
})

export const getV4AdminOperationIdUpdateOpIdResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "modify_date": zod.string()
})


/**
 * Deletes a list of operations
 * @summary Delete list of operations
 */
export const deleteV4AdminOperationsBody = zod.object({
  "op_ids": zod.array(zod.string().uuid())
})

export const deleteV4AdminOperationsResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "modify_date": zod.string()
})


/**
 * Delete an operation
 * @summary Delete an operation
 */
export const deleteV4AdminOperationsOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * Get list of Corteva team members in the operation.

Results are paginated. The page limit is 25 results by default, with an
allowed maximum of 1000 results per page.

 * @summary Get list of Corteva team members in the operation
 */
export const getV4OperationsOperationIdCortevaUserParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV4OperationsOperationIdCortevaUserQueryLimitMin55 = -1;

export const getV4OperationsOperationIdCortevaUserQueryLimitMax56 = 1000;
export const getV4OperationsOperationIdCortevaUserQueryOffsetMin57 = 0;


export const getV4OperationsOperationIdCortevaUserQueryParams = zod.object({
  "limit": zod.number().min(getV4OperationsOperationIdCortevaUserQueryLimitMin55).max(getV4OperationsOperationIdCortevaUserQueryLimitMax56).optional(),
  "offset": zod.number().min(getV4OperationsOperationIdCortevaUserQueryOffsetMin57).optional()
})

export const getV4OperationsOperationIdCortevaUserResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "users": zod.array( zod.object({
  "first": zod.string(),
  "last": zod.string(),
  "email": zod.string().nullable(),
  "phone": zod.string().nullable(),
  "granular_id": zod.string().uuid(),
  "modify_date": zod.string().optional(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional(),
  "inviter_id": zod.string().nullish(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
})
}))
})


/**
 * Removes all corteva users from an operation and stores the reason that the user booted the corteva team. Generates a new operation for the corteva team, and permissions them into that new operation
 * @summary Removes corteva team from an operation
 */
export const deleteV4OperationsOperationIdCortevaUserParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const deleteV4OperationsOperationIdCortevaUserBody = zod.object({
  "allow_copy_operation": zod.boolean(),
  "removal_reason": zod.string()
})


/**
 * After corteva has been booted from an operation, call this endpoint to reverse that boot
 * @summary Repermissions corteva team back to an operation
 */
export const postV4OperationsOperationIdCortevaUserParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * Get list of Corteva team members IDs and last modified date/time
 * @summary Get sync keys
 */
export const getV4OperationsOperationIdCortevaUserSyncKeysParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV4OperationsOperationIdCortevaUserSyncKeysResponse = zod.object({
  "metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "active": zod.array( zod.object({
  "key": zod.string().uuid(),
  "lastModified": zod.string().datetime().optional()
}))
})


/**
 * Get corteva users whose keys are in the request body
 * @summary Get sync data
 */
export const postV4OperationsOperationIdCortevaUserSyncDataParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const postV4OperationsOperationIdCortevaUserSyncDataBody = zod.object({
  "keys": zod.array(zod.string().uuid())
})

export const postV4OperationsOperationIdCortevaUserSyncDataResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "users": zod.array( zod.object({
  "first": zod.string(),
  "last": zod.string(),
  "email": zod.string().nullable(),
  "phone": zod.string().nullable(),
  "granular_id": zod.string().uuid(),
  "modify_date": zod.string().optional(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional(),
  "inviter_id": zod.string().nullish(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
})
}))
})


/**
 * Adds a corteva user for the operation. The corteva_type must be one of
"sales_rep", "territory_manager", "csa", "field_agronomist",
"strategic_account_manager".

The operation_id and user_id in body and URL must match.

 * @summary Adds corteva user
 */
export const putV4OperationsOperationIdCortevaUserUserIdParams = zod.object({
  "operation_id": zod.string().uuid(),
  "user_id": zod.string().uuid()
})

export const putV4OperationsOperationIdCortevaUserUserIdBody = zod.object({
  "corteva_type": zod.enum(['csa', 'field_agronomist', 'sales_rep', 'strategic_account_manager', 'territory_manager']),
  "brand_name": zod.string()
})


/**
 * Deletes the user from the given operation_id
 * @summary Delete user from operation
 */
export const deleteV4OperationsOperationIdCortevaUserUserIdParams = zod.object({
  "operation_id": zod.string().uuid(),
  "user_id": zod.string().uuid()
})


/**
 * Get all settings for the provided operation id
 * @summary Get all settings for the provided operation id
 */
export const getV4OperationsOperationIdSettingsParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV4OperationsOperationIdSettingsResponse = zod.object({
  "operation_id": zod.string().uuid().optional(),
  "autogen_fields": zod.boolean(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
})


/**
 * Updates the settings provided assuming they are valid keys. Can send the whole payload, or just a partial object.
 * @summary Update setting for the provided operation id
 */
export const putV4OperationsOperationIdSettingsParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const putV4OperationsOperationIdSettingsBody = zod.object({
  "operation_id": zod.string().uuid().optional(),
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
})

export const putV4OperationsOperationIdSettingsResponse = zod.object({
  "operation_id": zod.string().uuid().optional(),
  "autogen_fields": zod.boolean(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
})


/**
 * This endpoint creates a new operation within a business entity
and adds the creator to both the business and the operation.

 * @summary Creates an Operation within a Business Entity.
 */
export const postV4OperationsBody = zod.object({
  "id": zod.string().uuid().optional(),
  "name": zod.string().min(1),
  "creator_role": zod.enum(['administrator', 'advisor']).optional(),
  "created_by_aar": zod.boolean().optional(),
  "country": zod.enum(['US', 'CA'])
})

export const postV4OperationsResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}),
  "modify_date": zod.string(),
  "country": zod.string(),
  "permissions":  zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "overrides": zod.array( zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "application": zod.string()
})).optional()
}),
  "business_operation_id": zod.string().nullable()
})


/**
 * Get a list of operations belonging to the user. Pagination is available using the limit and offset query parameters.
 * @summary Get a list of operations belonging to the user
 */
export const getV4OperationsQueryParams = zod.object({
  "search_term": zod.string().optional(),
  "limit": zod.number().optional(),
  "offset": zod.number().optional()
})

export const getV4OperationsResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "operations": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}),
  "modify_date": zod.string(),
  "country": zod.string(),
  "permissions":  zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "overrides": zod.array( zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "application": zod.string()
})).optional()
}),
  "business_operation_id": zod.string().nullable()
}))
})


/**
 * Update an operation
 * @summary Update an operation
 */
export const putV4OperationsOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const putV4OperationsOperationIdBody = zod.object({
  "id": zod.string().uuid().optional(),
  "name": zod.string().min(1)
})

export const putV4OperationsOperationIdResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}),
  "modify_date": zod.string(),
  "country": zod.string(),
  "permissions":  zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "overrides": zod.array( zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "application": zod.string()
})).optional()
}),
  "business_operation_id": zod.string().nullable()
})


/**
 * Get operation by id
 * @summary Get operation by id
 */
export const getV4OperationsOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV4OperationsOperationIdResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}),
  "modify_date": zod.string(),
  "country": zod.string(),
  "permissions":  zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "overrides": zod.array( zod.object({
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string()),
  "application": zod.string()
})).optional()
}),
  "business_operation_id": zod.string().nullable()
})


/**
 * Delete an operation
 * @summary Delete an operation
 */
export const deleteV4OperationsOperationIdParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * gets operations based on the given sync keys.
 * @summary gets operations based on the given sync keys
 */
export const postV4OperationsSyncDataQueryParams = zod.object({
  "settings": zod.boolean().optional(),
  "user_perms": zod.boolean().optional(),
  "corteva": zod.string().optional()
})

export const postV4OperationsSyncDataBody = zod.object({
  "keys": zod.array(zod.string().uuid())
})

export const postV4OperationsSyncDataResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "operations": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
}),
  "settings":  zod.object({
  "autogen_fields": zod.boolean().optional(),
  "invoiced_matching": zod.boolean().optional(),
  "allow_corteva_access": zod.boolean().optional()
}).optional(),
  "user_permission":  zod.object({
  "roles-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "fields-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "enrollments-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "inputs-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "layers-store": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "activities-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "landvisor": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "financial_permission": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "imagery-svc": zod.record(zod.string(), zod.array(zod.string())).optional(),
  "nitrogen_permission": zod.record(zod.string(), zod.array(zod.string())).optional()
}).optional(),
  "modify_date": zod.string(),
  "corteva_type": zod.string().optional(),
  "brand_name": zod.string().optional()
}))
})


/**
 * Returns operation to cloned operation linking and removal information. Returns 404 if linkage not found.
 * @summary Fetch AAR information for the operation
 */
export const getV4OperationsOperationIdAarParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV4OperationsOperationIdAarResponse = zod.object({
  "operation":  zod.object({
  "third_party_id": zod.string(),
  "cloned_op_id": zod.string().uuid().nullable(),
  "operation_id": zod.string().uuid(),
  "on_farm_key": zod.string(),
  "operation_name": zod.string(),
  "source_system": zod.string(),
  "country": zod.string(),
  "create_date": zod.string().datetime(),
  "modify_date": zod.string().datetime(),
  "status": zod.boolean()
}),
  "removal":  zod.object({
  "removal_reason": zod.string(),
  "cloned_op_id": zod.string().uuid().nullable(),
  "operation_id": zod.string().uuid(),
  "boot_count": zod.number(),
  "allow_copy_data": zod.boolean()
}).optional(),
  "connections": zod.array( zod.object({
  "third_party_id": zod.string(),
  "cloned_op_id": zod.string().uuid().nullable(),
  "operation_id": zod.string().uuid(),
  "on_farm_key": zod.string(),
  "operation_name": zod.string(),
  "source_system": zod.string(),
  "country": zod.string(),
  "create_date": zod.string().datetime(),
  "modify_date": zod.string().datetime(),
  "status": zod.boolean()
})).optional()
})


/**
 * Get a list of operation information based on bpid/zipcode. Will return whether a combination was valid or not, as well as any matching operations.
 * @summary Get a list of operation information based on bpid/zipcode
 */
export const getV4OperationsByBpidQueryParams = zod.object({
  "bpid": zod.number().optional(),
  "zipcode": zod.string().optional()
})

export const getV4OperationsByBpidResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "is_valid": zod.boolean(),
  "operations": zod.array( zod.object({
  "operation_id": zod.string(),
  "operation_name": zod.string()
}))
})


/**
 * The current user becomes a member of linked operations defined by BPID/Zipcode.
 * @summary Current user becomes a member of operations
 */
export const postV4OperationsByBpidQueryParams = zod.object({
  "bpid": zod.number().optional(),
  "zipcode": zod.string().optional()
})

export const postV4OperationsByBpidBody = zod.object({
  "base_role": zod.enum(['administrator', 'advisor'])
})

export const postV4OperationsByBpidResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "is_valid": zod.boolean(),
  "operations": zod.array( zod.object({
  "operation_id": zod.string(),
  "operation_name": zod.string()
}))
})


/**
 * Fetches context information for the context_id passed in.
 * @summary Get context by id.
 */
export const getV4ContextContextIdParams = zod.object({
  "context_id": zod.string()
})

export const getV4ContextContextIdResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "permissions":  zod.object({
  "overrides": zod.array( zod.object({
  "application": zod.string().nullish(),
  "allow": zod.array(zod.string()).nullish(),
  "deny": zod.array(zod.string()).nullish()
})).optional(),
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string())
}),
  "children": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "type": zod.string().nullish(),
  "role": zod.string().nullish(),
  "brand": zod.string().nullish(),
  "third_party_id": zod.string().nullish(),
  "op_metadata":  zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "country": zod.string().nullish(),
  "role": zod.string().nullish(),
  "create_date": zod.string().datetime().nullish(),
  "modify_date": zod.string().datetime().nullish(),
  "permissions":  zod.object({
  "overrides": zod.array( zod.object({
  "application": zod.string().nullish(),
  "allow": zod.array(zod.string()).nullish(),
  "deny": zod.array(zod.string()).nullish()
})).optional(),
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string())
}).nullish(),
  "settings":  zod.object({
  "allow_corteva_access": zod.boolean().nullish(),
  "autogen_fields": zod.boolean().nullish(),
  "invoiced_matching": zod.boolean().nullish()
}).nullish()
}).nullish()
})),
  "type": zod.string().nullish(),
  "brand": zod.string().nullish(),
  "third_party_id": zod.string().nullish(),
  "role": zod.string()
})


/**
 * Fetches a list of available contexts for the user making the request.
 * @summary Get context list for user.
 */
export const getV4ContextResponse = zod.object({
  "data": zod.array( zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "permissions":  zod.object({
  "overrides": zod.array( zod.object({
  "application": zod.string().nullish(),
  "allow": zod.array(zod.string()).nullish(),
  "deny": zod.array(zod.string()).nullish()
})).optional(),
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string())
}),
  "children": zod.array( zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "type": zod.string().nullish(),
  "role": zod.string().nullish(),
  "brand": zod.string().nullish(),
  "third_party_id": zod.string().nullish(),
  "op_metadata":  zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "country": zod.string().nullish(),
  "role": zod.string().nullish(),
  "create_date": zod.string().datetime().nullish(),
  "modify_date": zod.string().datetime().nullish(),
  "permissions":  zod.object({
  "overrides": zod.array( zod.object({
  "application": zod.string().nullish(),
  "allow": zod.array(zod.string()).nullish(),
  "deny": zod.array(zod.string()).nullish()
})).optional(),
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string())
}).nullish(),
  "settings":  zod.object({
  "allow_corteva_access": zod.boolean().nullish(),
  "autogen_fields": zod.boolean().nullish(),
  "invoiced_matching": zod.boolean().nullish()
}).nullish()
}).nullish()
})),
  "type": zod.string().nullish(),
  "brand": zod.string().nullish(),
  "third_party_id": zod.string().nullish(),
  "role": zod.string()
}))
})


/**
 * Gets operations a context has access to.
 * @summary Get operations by context id.
 */
export const getV4ContextContextIdOperationParams = zod.object({
  "context_id": zod.string()
})

export const getV4ContextContextIdOperationResponse = zod.object({
  "data": zod.array( zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "country": zod.string().nullish(),
  "role": zod.string().nullish(),
  "create_date": zod.string().datetime().nullish(),
  "modify_date": zod.string().datetime().nullish(),
  "permissions":  zod.object({
  "overrides": zod.array( zod.object({
  "application": zod.string().nullish(),
  "allow": zod.array(zod.string()).nullish(),
  "deny": zod.array(zod.string()).nullish()
})).optional(),
  "allow": zod.array(zod.string()),
  "deny": zod.array(zod.string())
}).nullish(),
  "settings":  zod.object({
  "allow_corteva_access": zod.boolean().nullish(),
  "autogen_fields": zod.boolean().nullish(),
  "invoiced_matching": zod.boolean().nullish()
}).nullish()
}))
})


/**
 * Get list of users in the operation
 * @summary Get list of users in the operation
 */
export const getV4OperationsOperationIdUsersParams = zod.object({
  "operation_id": zod.string().uuid()
})

export const getV4OperationsOperationIdUsersQueryParams = zod.object({
  "limit": zod.number().optional(),
  "offset": zod.number().optional()
})

export const getV4OperationsOperationIdUsersResponse = zod.object({
  "_metadata":  zod.object({
  "count": zod.number(),
  "limit": zod.number(),
  "offset": zod.number()
}),
  "users": zod.array( zod.object({
  "first": zod.string(),
  "last": zod.string(),
  "email": zod.string().nullable(),
  "phone": zod.string().nullable(),
  "granular_id": zod.string().uuid(),
  "modify_date": zod.string().datetime().optional(),
  "user_role":  zod.object({
  "base_role": zod.string(),
  "financial": zod.boolean(),
  "nitrogen": zod.boolean()
})
}))
})


/**
 * Updates the list of users to the roles associated to the business rules provided
 * @summary Update users roles
 */
export const putV4OperationsOperationIdUsersParams = zod.object({
  "operation_id": zod.string().uuid()
})


/**
 * Deletes the user from the given operation_id
 * @summary Delete user from operation
 */
export const deleteV4OperationsOperationIdUsersUserIdParams = zod.object({
  "operation_id": zod.string().uuid(),
  "user_id": zod.string().uuid()
})


