/**
 * THIS IS **JUST AUTHENTICATION/LOGIN**. IT TALKS TO THE AUTH SERVICE AND
 * POPULATES LOCALSTORAGE (FOR NOW) WITH TOKENS. DO NOT POLLUTE WITH CRAP LIKE
 * DATADOG OR SEGMENT OR LAUNCHDARKLY. KEEP THAT SHIT AWAY (AND IN THE
 * CONTAINER). PLEASE KEEP THIS SIMPLE AND DON'T COMPLEXIFY WITH STATE
 * MANAGEMENT THIS MODULE IS NOT SUPPOSED TO DEAL WITH.
 *
 * STOP IT. EVEN IF YOU DON'T, \@nikhilanand IS WATCHING THIS LIKE A HAWK.
 *
 * 👉 No hooks. No Jotai. No Zustand. No Redux. No Recoil. NONE OF THAT. Just
 *   persist two valid tokens (Identity and Refresh) and render the children
 *   when done. THAT IS ALL. Any other concerns should be their own component
 *   (as a child of this one).
 *
 * This is complicated enough as it is. KEEP IT SIMPLE.
 *
 * Enough yelling. Sorry 🌸 🤗
 *
 * TODO: Don't use LocalStorage for any kind of 'sensitive' information like
 *       JWTs. Refresh Tokens are not exempt, and I don't have any idea why we
 *       were OK with storing them in LS but not Identity Tokens! To
 *       read/watch:
 *
 *       https://dev.to/rdegges/please-stop-using-local-storage-1i04/comments
 *
 */

import React, { useEffect } from "react";

import { LOCAL_STORAGE_BOOTSTRAP_MUTEX } from "@granular/fabric3-definitions";

import Loading from "../components/Loading";
import { useLocalStorage } from "../hooks/localStorage";
import { useInitializeAuth } from "../services/auth/auth";

// I REFUSE TO USE GODDAMN ENUMS.
const MUTEX_STATUS = {
  MOUNTED_ONCE: "MOUNTED_ONCE",
  MOUNTED_AGAIN: "MOUNTED_AGAIN",
  AUTH_COMPLETE: "AUTH_COMPLETE",
};

const Wrapper = (props: React.PropsWithChildren) => {
  const initializeAuth = useInitializeAuth();
  /**
   * React in Dev/Strict mode will double-render this component. That's a
   * feature and not a bug. This has led to the infamous "Localhost Looping
   * Problem".
   *
   * NOTE: React **will not** do this in production! It's a dev-only thing. To
   * see their rationale, please read this:
   *
   * https://tinyurl.com/5x2xm2yr
   *
   * We have a situation where we want to detect this double-render: we do not
   * wish to call the Authentication Service twice **if there's a ?code param**
   * in the URI. Recall that we exchange this one-time auth code for new
   * tokens. In a double-render, **the ?code will stay the same**! And because
   * it's a one-time code, the Authentication Service will barf 🤢 🤮
   *
   * When it does, you'll be taken to SSO, which will issue the same code. And
   * the Auth Service will barf with this stale code. And then you'll be taken
   * to SSO, which will issue the same... and now you have a lovely login loop.
   *
   * To prevent this unpleasantness, let's make a small mutex in Local Storage
   * that we can update. We need a store of state _external_ to the component
   * since React (again, in Dev/Strict mode) will fully umount and remount the
   * component, thereby destroying any state (i.e., a naïve `useState` won't
   * solve this).
   */

  /**
   * We will initialize this component with a `MOUNTED_ONCE` value and set the
   * mutex to `MOUNTED_AGAIN` on component mount using an effect. In _another_
   * effect, we keep an eye on this mutex's value.
   *
   * NOTE: Unless you specify an `overwrite=false` flag, this hook will work in
   * upsert mode.
   */
  const [mutex, setMutex] = useLocalStorage(
    LOCAL_STORAGE_BOOTSTRAP_MUTEX,
    MUTEX_STATUS.MOUNTED_ONCE,
  );

  useEffect(() => {
    if (mutex === MUTEX_STATUS.MOUNTED_ONCE) {
      setMutex(MUTEX_STATUS.MOUNTED_AGAIN);
    }
  }, [mutex, setMutex]);

  /**
   * Now keep track of the mutex. If we have `MOUNTED_AGAIN`, we proceed with
   * the authentication flow described at the head of this module. If all goes
   * well, we set the mutex to `AUTH_COMPLETE` and use that value to
   * conditionally render the child component(s) or a giant-ass loading
   * spinner. Fin!
   */
  useEffect(() => {
    (async () => {
      if (mutex === MUTEX_STATUS.MOUNTED_AGAIN) {
        if (navigator.onLine) {
          const initialized = await initializeAuth();
          if (initialized) {
            setMutex(MUTEX_STATUS.AUTH_COMPLETE);
          }
        } else {
          console.log("Skipping refresh because you're offline.");
          setMutex(MUTEX_STATUS.AUTH_COMPLETE);
        }
      }
    })().catch((error) => {
      console.log(
        "Error in the Login component trying to juggle tokens.",
        error,
      );
    });
  }, [mutex, setMutex, initializeAuth]);

  return mutex === MUTEX_STATUS.AUTH_COMPLETE ? props.children : <Loading />;
};

export default Wrapper;
