/**
 * Let's just do a passthrough to the 'raw' Analytics object (like we do with
 * the LaunchDarkly Feature Flags).
 *
 * At least for now. There's nice and big wrapper to Segment in the old Core.
 * We'll summon it as and when people need any advanced features.
 */

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const segment = window.analytics as SegmentAnalytics.AnalyticsJS;
