import { useEffect, useRef } from "react";
import type { EffectCallback } from "react";

/**
 * A hook to replace useEffect that only runs once (when component is mounted)
 * even in dev mode.
 *
 * TODO: Is this necessary? `useEffect` is one of the worst design decisions
 *       (IMHO) but is it better for it to be evident than hidden under a small
 *       abstraction? Why not use a Class Component (first-class in React,
 *       still) with an easy-to-read lifecycle method (`componentDidMount`)?
 *
 * @param effect-The Callback to run on mount
 */
export const useMount = (effect: EffectCallback): void => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      return;
    }

    mounted.current = true;

    return effect();
  }, [effect]);
};
