import {
  DEFAULT_LOCALE,
  type SupportedLocale,
} from "@granular/fabric3-definitions";
import type React from "react";
import { type IntlConfig, IntlProvider } from "react-intl";
import { useUserLocale } from "../hooks/locale/useUserLocale";

/**
 * A higher-order component that wraps a component with an IntlProvider
 * configured with the user's locale.
 *
 * This is useful when we want to localize a library with multiple shared
 * components.
 *
 * This HOC will wrap the component with an IntlProvider with the translations
 * exclusively used by the component. The consumers don't need to know each
 * translation key that component uses and add it to their app's translations.
 * This makes the localization of shared components independent from the apps
 * and easy to manage.
 */
export const withIntlProvider = <P extends object>(
  WrappedComponent: React.FC<P>,
  intlConfig: {
    translations: Record<
      SupportedLocale,
      Pick<IntlConfig, "messages">["messages"]
    >;
  } & Omit<IntlConfig, "locale" | "defaultLocale" | "messages">,
) => {
  // eslint-disable-next-line react/display-name
  return (props: P) => {
    const locale = useUserLocale();
    const { translations, ...restIntlConfig } = intlConfig;

    return (
      <IntlProvider
        locale={locale}
        messages={translations[locale]}
        defaultLocale={DEFAULT_LOCALE}
        {...restIntlConfig}
      >
        <WrappedComponent {...props} />
      </IntlProvider>
    );
  };
};
