// eslint-disable-next-line canonical/filename-no-index
import enCA from "./en-CA.json";
import enUS from "./en-US.json";
import frCA from "./fr-CA.json";

export const Translations = {
  "en-US": enUS,
  "en-CA": enCA,
  "en-AU": {},
  "fr-CA": frCA,
  "pt-BR": {},

  /**
   * Need this since the User Service stores locales in lower case for
   * some strange, historical reason.
   */
  "en-us": enUS,
  "en-ca": enCA,
  "fr-ca": frCA,
  "pt-br": {},

  /**
   * Fallbacks
   */
  en: enUS,
  fr: frCA,
};
