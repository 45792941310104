/**
 * Feature Flag wrapper for the Core Shell. We use LaunchDarkly to manage our
 * feature-flags. That's what the `LD` here means.
 *
 * This could've been an easy one-liner:
 * https://github.com/launchdarkly/react-client-sdk/blob/main/examples/hoc/src/universal/app.js
 *
 * If we just did that, we'd still get telemetry but it would be from
 * *anonymous* users. Product would like to be a bit more... 'involved' and
 * trace things down to a single, identified user.
 *
 * What this means is that we'll need to make a call to the User Service to
 * retrieve the user's name, email, Granular ID, and so on. This is why this
 * layer/wrapper must be wrapped properly (see `index.tsx`). That's the only
 * complexity we need to deal with.
 *
 * Again 👉 THAT'S THE ONLY COMPLEXITY WE NEED TO DEAL WITH.
 *
 * Banish any thoughts about persisting Feature Flags anywhere but in memory. I
 * actually didn't even want to use the React-specific SDK since all you need
 * here is a closure, but whatever. It's offered by LaunchDarkly and it's there
 * and we'll use it.
 *
 * Finally, this "GET ALL THE FLAGS AT APPLICATION BOOTSTRAP" idea did not
 * emerge exclusively from \@nikhilanand's head:
 *
 * https://docs.launchdarkly.com/sdk/client-side/javascript/default-values#bootstrap-the-flag-values-to-be-available-immediately
 *
 * It's just the simplest way to do things. Let's do that and worry about
 * "performance" and edge-cases as they arise and just save our enthusism for
 * other things, you know? 🌸
 *
 */

import { LAUNCH_DARKLY_KEYS } from "@granular/fabric3-definitions";
import {
  asyncWithLDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import React from "react";
import { getEnvironment } from "../helpers/environment";
import { useUser } from "../hooks/useUser";
import Loading from "../components/Loading";

/**
 * As we are depending on LD flags to check if an application will be
 * visible/routable in Container, We need to ensure that the LDProvider is
 * loaded before the Container is rendered but we don't need to wait for the
 * flags to be loaded, as we are using local storage to bootstrap the flags.
 * This ensures that our app does not flicker due to flag changes at startup
 * time.
 */
const LDProvider = React.lazy(async () => ({
  default: await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_KEYS[getEnvironment()],
    reactOptions: { useCamelCaseFlagKeys: false },
    // we don't need to wait for remote flags, we initially the app with local
    // storage values
    options: { bootstrap: "localStorage" },
    // the amount of time, in seconds, to wait for initialization before
    // rejecting the promise
    timeout: 5, // 5 seconds
  }),
}));

const LDContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: user } = useUser();
  const ldClient = useLDClient();

  /**
   * Identify the user with LaunchDarkly We're doing this after the LD client
   * is initialized so this won't interfere with the initial time to load the
   * app
   */
  React.useEffect(() => {
    if (ldClient && user) {
      if (user.granularId) {
        void ldClient.identify({
          kind: "user",
          key: `user-key-${user.granularId}`,
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
        });
      } else {
        return;
      }
    }
  }, [ldClient, user]);

  return children;
};

const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <LDProvider>
        <LDContext>{children}</LDContext>
      </LDProvider>
    </React.Suspense>
  );
};

export default Wrapper;
