/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, canonical/id-match */

/**
 * This is a very basic implementation of Segment tracking. We have an overtly
 * eager implementation (in \@nikhilanand's opinion) in the old Fabric/Core that
 * is either unnecessary or irrelevant:
 *
 * https://gitlab.internal.granular.ag/granular/fabric/core/-/blob/master/src/legacy/segment/SegmentService.ts?ref_type=heads
 *
 * Here, just expose the Segment Analytics object on `window` and provide
 * its typings which you can see in `../helpers/segment.ts`
 */

import {
  SEGMENT_APP_NAME,
  SEGMENT_KEYS,
  SEGMENT_OBJECT,
  SegmentIdentifier,
} from "@granular/fabric3-definitions";
import { useMount } from "../hooks/mount";
import { getEnvironment } from "../helpers/environment";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    [SEGMENT_OBJECT]: any;
  }
}

const Component = (props: React.PropsWithChildren & SegmentIdentifier) => {
  useMount(() => {
    const { id } = props;
    const key = SEGMENT_KEYS[getEnvironment()];

    window.analytics._writeKey = key;
    window.analytics.SNIPPET_VERSION = "5.2.0";

    if (typeof window.analytics.load === "function") {
      window.analytics.load(key);
      window.analytics.identify(id, {
        appName: SEGMENT_APP_NAME,
      });

      window.analytics.page();
    }
  });

  return props?.children;
};

export default Component;

/* eslint-enable */
