import {
  DEFAULT_COUNTRY,
  SUPPORTED_COUNTRIES,
} from "@granular/fabric3-definitions";

/**
 * Returns the country the application is deployed in.
 * NOTE: This has *absolutely nothing* to do with the user's locale
 * preferences or the browser's locale setting!
 * For localhost it will return DEFAULT_COUNTRY (currently "us")
 */
export const useCountryFromUrl = () => {
  const { hostname } = window.location;

  // force casting because the hostname will always be a string
  const countryInURL = hostname.split(".")[0] as
    | (typeof SUPPORTED_COUNTRIES)[number]
    | undefined;

  return countryInURL && SUPPORTED_COUNTRIES.includes(countryInURL)
    ? countryInURL
    : DEFAULT_COUNTRY;
};
