import type { RPCRouter } from "@granular/fabric3-server";
import type { inferRouterOutputs } from "@trpc/server";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import React from "react";
import {
  default as authzUserCan,
  UserActions,
  PermissionModel,
} from "@granular/authz";
import { contextSelectedState } from "../state/core";
import { coreQueryClient } from "../services/trpc";

type RouterOutput = inferRouterOutputs<RPCRouter>;

export type ContextResponse = RouterOutput["bizContext"]["getContext"];
export type SingleContext = RouterOutput["bizContext"]["getContext"][number];

/**
 * This hook is used to get the Context/Agency data from the API.
 * @returns
 * - allContexts: All the contexts/agency data from the API.
 * - contextSelected: The context/agency selected by the user. The selected context is stored by the app name:
 *   If the FF isPersistContextByAppEnabled is enabled, so each app can have its own selected context.
 *   If the FF isPersistContextByAppEnabled is disabled, we use the "default" key to store the selected context for all apps.
 * - setContextSelected: Function to set the context/agency selected according to the current app.
 * - isLoading: always false due to the useSuspenseQuery.
 * - isError: always false due to the useSuspenseQuery.
 */
export function useOperatingContext() {
  // For the root context we use the "default" key to store the selected context.
  const appName = "default";
  /**
   * Context is a mandatory information for the entire ecosystem, so we use suspense query here
   * instead of the useQuery to ensure that Context data will be loaded before rendering anything.
   * With SuspenseQuery, the data will never return undefined, and the fallback
   * Loading component (from Container) will be shown until the data is loaded.
   */
  const [allContexts, { isError, isLoading }] =
    coreQueryClient.bizContext.getContext.useSuspenseQuery(undefined, {
      staleTime: 60 * 1000 * 60 * 24,
    });

  const [contextSelected, setContextSelected] = useAtom(contextSelectedState);

  /**
   * Check if the context passed as parameter is a valid context.
   * To be valid the context must exists in the allContexts array.
   * @returns a boolean indicating if the context is valid.
   */
  const isValidContext = React.useCallback(
    (context: SingleContext | undefined) => {
      if (!context) return false;
      return allContexts?.some(
        ({ contextId }) => contextId === context.contextId,
      );
    },
    [allContexts],
  );

  /**
   * Set the global context according to the new context selected.
   * The context selected will be the first valid context found in the following order:
   * 1. The context selected by the user (obviously, if it's valid)
   * 2. The current context (nothing will change)
   * 3. The first context in the allContexts array (fallback)
   * @returns the new context selected or undefined if no valid context was found (if user don't have any context)
   */
  const setValidContextSelect = React.useCallback(
    (newContext: SingleContext | undefined) => {
      // Only mutate the state when new context is defined and it's different from the current one
      setContextSelected((currentContextSelected) => {
        // this can be undefined if the user doesn't have any context
        let validContext: SingleContext | undefined;
        if (isValidContext(newContext)) {
          validContext = newContext;
        } else if (isValidContext(currentContextSelected[appName])) {
          validContext = currentContextSelected[appName];
        } else {
          validContext = allContexts?.[0];
        }

        return newContext &&
          newContext.contextId !== currentContextSelected[appName]?.contextId
          ? {
              ...currentContextSelected,
              [appName]: validContext,
            }
          : currentContextSelected;
      });
    },
    [allContexts, appName, isValidContext, setContextSelected],
  );

  const selectedContext = contextSelected[appName] ?? allContexts?.[0];

  /**
   * Checks where or not the user is allowed to perform the given action for the
   * given model for the currently selected context
   * @param action - The user action defined in authz
   * @param model - The model to verify the user permissions for this action
   * @returns Boolean indicating userCan
   */
  const userCan = (action: UserActions, model: PermissionModel) => {
    if (selectedContext?.permissions === undefined) {
      return false;
    }
    const ctx = {
      permissions: {
        allow: selectedContext.permissions.allow,
        deny: selectedContext.permissions.deny,
        overrides: selectedContext.permissions.overrides ?? undefined,
      },
    };
    return authzUserCan(action, model, ctx);
  };

  /**
   * Clear all saved context data from localStorage, and reset the Jotai State Atom
   */
  const resetLocalStoredContexts = () => {
    setContextSelected(RESET);
  };

  // isLoading and isError are returned only for compatibility with old codebase
  return {
    allContexts,
    contextSelected: selectedContext,
    setContextSelected: setValidContextSelect,
    resetLocalStoredContexts,
    userCan,
    isLoading,
    isError,
  };
}
